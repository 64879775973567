import React, {useEffect, useMemo, useState} from 'react';
import styled from '../../../styles/editorPage/rightBarEditor.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    getActiveComponent,
    getComponentCollection,
    getComponentCollectionMobile,
    getDevice,
    getLang,
    getRightBarMenu
} from "../../../redux/selectors/editorSelectors";
import {setRightBarMenu, setStylesActiveComponent} from "../../../redux/reducers/editorReducer";
import Templates from "./TemplatesForAdd/Templates";
import SideStyles from "./SideStyles";

const RightBarEditor = ({isHide, isActivePage}) => {

    const idActive = useSelector(state => getActiveComponent(state));
    const componentCollection = useSelector(state => getComponentCollection(state));
    const componentCollectionMobile = useSelector(state => getComponentCollectionMobile(state));
    const rightBarMenu = useSelector(state => getRightBarMenu(state));
    const device = useSelector(state => getDevice(state));
    const lang = useSelector(state => getLang(state));
    const activeComponent = useSelector(state => getActiveComponent(state));

    const [parentComponent, setParentComponent] = useState('')
    const [parentStylesTextSliced, setParentStylesTextSliced] = useState('')
    const dispatch = useDispatch()

    //Находим активный компонент
    const data = useMemo(() => {
        if (idActive.length) {
            let firstLevel = {}
            if (device === 'desktop') {
                firstLevel = componentCollection.filter(item => item.id === idActive[0])[0]
            } else {
                firstLevel = componentCollectionMobile.filter(item => item.id === idActive[0])[0]
            }
            setParentComponent(firstLevel?.name)
            if (idActive.length === 1) {
                return firstLevel
            }
            let twoLevelComponents = firstLevel?.components
            let twoLevel = twoLevelComponents.filter(item => item.id === idActive[1])[0]
            if (idActive.length === 2) {
                return twoLevel
            }
            let threeLevelComponents = twoLevel?.components
            let threeLevel = threeLevelComponents?.filter(item => item.id === idActive[2])[0]
            if (idActive.length === 3) {
                return threeLevel
            }
            setParentStylesTextSliced(threeLevel.styles)
            let fourLevel = threeLevel[lang]?.filter(item => item.id === idActive[3])[0]
            if (idActive.length === 4) {
                return fourLevel
            }
        }
        return null
    }, [idActive, componentCollection, componentCollectionMobile])

    //Находим компонент для замены
    const dataReplacement = useMemo(() => {
        if (idActive.length) {
            let firstLevel = {}
            if (device === 'desktop') {
                firstLevel = componentCollection.filter(item => item.id === idActive[0])[0]
            } else {
                firstLevel = componentCollectionMobile.filter(item => item.id === idActive[0])[0]
            }
            if (idActive.length >= 3) {
                let twoLevelComponents = firstLevel?.components
                let twoLevel = twoLevelComponents.filter(item => item.id === idActive[1])[0]
                let threeLevelComponents = twoLevel?.componentsReplacement
                let threeLevel = threeLevelComponents?.filter(item => item.id === idActive[2])[0]
                return threeLevel
            }
        }
        return null
    }, [idActive, componentCollection, componentCollectionMobile])


    const changeParams = (typeParams, params, isReplacement) => {
        dispatch(setStylesActiveComponent(typeParams, params, isReplacement))
    }

    const setMenuActive = () => {
        dispatch(setRightBarMenu(!rightBarMenu))
    }

    //Закрывает свойства компонента при отсутствии активного
    useEffect(() => {
        if (!activeComponent.length) {
            dispatch(setRightBarMenu(false))
        }
    }, [activeComponent])

    return (
        <div className={`${styled.rightbarContainer} ${isHide ? 'hide' : ''}`}>
            {!isActivePage ?
                <>
                    <div className={`${styled.sideContainer} ${rightBarMenu ? styled.sideActive : ''}`}>
                        <SideStyles
                            data={data}
                            dataReplacement={dataReplacement}
                            changeParams={changeParams}
                            setMenuActive={setMenuActive}
                            parentComponent={parentComponent}
                            parentStylesTextSliced={parentStylesTextSliced}
                        />
                    </div>
                    <div className={styled.rightbar}>
                        <div className={styled.rightbarHeader}>
                            <div
                                className={`${styled.title}`}
                            >
                                Компоненты
                            </div>
                            <div
                                className={`${styled.setting}`}
                                onClick={() => setMenuActive('design')}
                            >
                                <span className="f-icon icon-equalizer fs-20 hover-blue "/>
                            </div>
                        </div>
                        <div className={styled.rightbarBody}>
                            <Templates/>
                        </div>
                    </div>
                </>
                :
                <div className={styled.viewMode}>
                    <div className={styled.head}><b><span
                        style={{color: '#1790ff'}}
                        className="f-icon icon-notification-circle fs-17 mr-7"
                    /></b>Внимание
                    </div>
                    <div className={styled.text}>
                        Вы находитесь в режиме просмотра, так как эта страница активна в данный момент. Любые изменения
                        запрещены!
                    </div>
                </div>
            }
        </div>
    );
};

export default RightBarEditor;

