import {useDispatch, useSelector} from "react-redux";
import {
	getActiveComponent,
	getComponentCollection, getComponentCollectionMobile,
	getDevice,
	getIsHide, getLang, getRightBarMenu
} from "../redux/selectors/editorSelectors";
import {useEffect, useRef, useState} from "react";
import {setActiveComponent, setRightBarMenu} from "../redux/reducers/editorReducer";


export function useBorderActive(component, id) {
	const idActive = useSelector(state => getActiveComponent(state));
	const collection = useSelector(state => getComponentCollection(state));
	const collectionMobile = useSelector(state => getComponentCollectionMobile(state));
	const isHide = useSelector(state => getIsHide(state));
	const lang = useSelector(state => getLang(state));
	const rightBarMenu = useSelector(state => getRightBarMenu(state));

	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)
	const [load, setLoad] = useState(false)

	const ref = useRef()

	const [changeImage, setChangeImage] = useState(false)

	const firstLoad = () => {
		if (changeImage && !load) {
			setLoad(true)
			setChangeImage(false)
		}
	}
	const firstLoadSwiper = () => {
		!load && setLoad(true)
	}
	const resize = () => {
		setWidth(ref?.current?.offsetWidth)
		setHeight(ref?.current?.offsetHeight)
	}

	useEffect(() => {
			resize()
	}, [collection, collectionMobile, lang])

/*	useEffect(() => {
		const timer = setTimeout(() => {
			setWidth(ref?.current?.offsetWidth)
			setHeight(ref?.current?.offsetHeight)
		}, 500);
		return () => clearTimeout(timer);
	}, [device])*/

	useEffect(() => {
		if (component?.styles?.gridTemplateColumns) {
			setChangeImage(true)
		}
	}, [component?.styles?.gridTemplateColumns])

	const dispatch = useDispatch()

	const selectThisComponent = (event) => {
		event.stopPropagation()
		dispatch(setActiveComponent(id))
		if (!rightBarMenu) {
			dispatch(setRightBarMenu(true))
		}
	}
	const selectMainComponent = (event) => {
		event.preventDefault();
		event.stopPropagation();
		if(event.button == 2){
			dispatch(setActiveComponent(id))
			if (!rightBarMenu) {
				dispatch(setRightBarMenu(true))
			}
		}
	}

	return {
		width, height, isHide,
		idActive, selectThisComponent,
		firstLoad, load, setChangeImage, ref, firstLoadSwiper,
		selectMainComponent, resize
	}
}