import React, { forwardRef, useMemo, useRef } from 'react';
import styled from '../../../../styles/editorPage/workField.module.scss'
import {useSelector} from "react-redux";
import BorderActive from "../../workFields/BorderActive";
import { getLang, getTheme } from "../../../../redux/selectors/editorSelectors";
import {useBorderActive} from "../../../../hooks/useBorderActive";
import { useDynamicGradientBg } from "./useDynamicGradientBg";

const TextSticky = forwardRef((props, refImg) => {

	const {mainId, blockId, component, isActivePage, collection} = props

	const lang = useSelector(state => getLang(state));
	const theme = useSelector(getTheme)

	const textRef = useRef(null);

	const {
		width, height, isHide, ref, resize,
		idActive, selectThisComponent,
	} = useBorderActive(component, [mainId, blockId, component.id])

	const gradientText = useDynamicGradientBg(refImg, ref, theme === "light" ? component.styles.color : component.styles.colorDark, theme === "light" ? component.styles.invertedColor : component.styles.invertedColorDark)

	const select = (e) => {
		resize()
		selectThisComponent(e)
	}

	return (
		<div
			className={styled.frontSpaceWrapper}
			style={{ alignSelf: component?.styles.alignSelf }}
		>
			<div
				className='p-relative'
				style={{
				margin: component.styles.margin.join(' '),
			}}>
				{!isHide && idActive.length === 3 && component.id === idActive[2] && !isActivePage &&
				<BorderActive
					width={width}
					height={height}
					id={[mainId, blockId, component.id]}
					large={true}
					collection={collection}
					container={true}
				/>
				}
				<div
					ref={ref}
					className={`${styled.invert} ${styled.text}`}
					style={{
						fontWeight: component.styles.fontWeight,
						fontSize: component.styles.fontSize,
						fontFamily: component.styles.fontFamily,
						fontStyle: component.styles.fontStyle || 'normal',
						textAlign: component.styles?.textAlign,
						backgroundImage: gradientText
					}}
					onClick={select}
				>
					{component.text[lang]}
				</div>
			</div>
		</div>
	);
});

export default TextSticky;