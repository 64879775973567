import React, {useCallback, useEffect, useRef, useState} from "react";
import uuid from "react-uuid";
import {useSelector} from "react-redux";
import {Button, Divider, Input} from "antd";
import {Swiper, SwiperSlide} from "swiper/react";
import SettingText from "../../../RightBar/Settings/SettingText";
import ModalFileManager from "../../../../Modals/ModalFileManager";
import SettingStyles from "../../../RightBar/Settings/SettingStyles";
import styled from "../../../../../styles/stories/stories.module.scss"
import {text} from "../../../../../helpers/initialStylesForComponents";
import SettingStylesMain from "../../../RightBar/Settings/SettingStylesMain";
import ThumbnailImage from "../../../../../assets/imagesBgBlock/vertical.jpg"
import {getLang, getLangList} from "../../../../../redux/selectors/editorSelectors";
import styledComponent from '../../../../../styles/editorPage/rightBarEditor.module.scss'
import {CloseOutlined, DeleteOutlined, FileImageOutlined, FontSizeOutlined, PlusOutlined} from "@ant-design/icons";

/**
 * @author Zholaman Zhumanov
 * @created 11.07.2024
 * @description
 * @last-updated
 * @update-description
 * @todo refactoring, delete heading
 * @fixme add heading
 * @param props
 * @constructor
 */
const StoriesSlider = (props) => {
    const {changeParams, stateData, toggleModal} = props;

    const timerUpdateOptionsRef = useRef(null);

    const lang = useSelector(state => getLang(state));
    const langList = useSelector(state => getLangList(state));

    const [swiper, setSwiper] = useState(null);
    const [categoryIds, setCategoryIds] = useState("");
    const [activeStories, setActiveStories] = useState({});
    const [updateOptions, setUpdateOptions] = useState(false);
    const [categoryIdsData, setCategoryIdsData] = useState([]);
    const [storiesData, setStoriesData] = useState(stateData || []);
    const [modalFileManager, setModalFileManager] = useState(false);

    /**
     * @description Переключение для файл менеджер
     */
    const toggleFileManagerModal = () => setModalFileManager(!modalFileManager)

    /**
     * @author Zholaman Zhumanov
     * @description Обновление данных
     * @param data
     * @param type
     * @param action
     */
    const updateStories = (data, type, action) => {
        if (!type) return

        try {
            const currentID = activeStories?.guid
            const savePrevData = storiesData?.filter((stories) => stories?.guid !== currentID)
            const currentActiveData = storiesData.filter((stories) => stories?.guid === currentID)?.[0]

            let newData = []
            let currentUpdateData = {}

            const updateData = (updateFields) => {
                return [
                    ...savePrevData,
                    {
                        ...updateFields
                    }
                ].sort((a, b) => a.id - b.id)
            }

            const commonData = {
                id: currentActiveData?.id,
                guid: currentActiveData?.guid,
                image: currentActiveData?.image,
                categories: currentActiveData?.categories,
                title: currentActiveData?.title,
                styles: currentActiveData?.styles,
            };

            switch (type) {
                case "image":
                    currentUpdateData = {
                        ...commonData,
                        image: data
                    }
                    break;
                case "category":
                    const pattern = /\/product\/(\d+)/;
                    const productId = action !== "remove" ? data?.match(pattern) : null
                    const updateCategories = action === "remove" ?
                        currentActiveData?.categories.filter(item => item != data) :
                        [...currentActiveData?.categories, productId?.[1]]
                    console.log("productId", productId)
                    currentUpdateData = {
                        ...commonData,
                        categories: updateCategories
                    }

                    setCategoryIdsData(updateCategories)
                    break;
                case "heading":
                    currentUpdateData = {
                        ...commonData,
                        title: data
                    }
                    break;
                case "heading_remove":
                    currentUpdateData = {
                        ...commonData,
                        title: ""
                    }
                    break;
                case "heading_text":
                    currentUpdateData = {
                        ...commonData,
                        title: {
                            ...currentActiveData?.title,
                            text: {
                                ...currentActiveData?.title?.text,
                                ...data
                            },
                        },
                    }
                    break;
                case "heading_styles":
                    currentUpdateData = {
                        ...commonData,
                        title: {
                            ...currentActiveData?.title,
                            styles: {
                                ...currentActiveData?.title?.styles,
                                ...data,
                            },
                        },
                    }
                    break;
                case "position":
                    currentUpdateData = {
                        ...commonData,
                        styles: data
                    }
                default:
                    break;
            }

            newData = updateData(currentUpdateData);
            setStoriesData(newData);
            // setActiveStories(currentUpdateData);

            // if (type === "image") {
            //     // Обновление картинки
            //     newData = [
            //         ...savePrevData,
            //         {
            //             id: currentActiveData?.id,
            //             guid: currentActiveData?.guid,
            //             image: type === "image" ? data : currentActiveData?.image,
            //             categories: currentActiveData?.categories,
            //             title: currentActiveData?.title,
            //             styles: currentActiveData?.styles,
            //         }
            //     ].sort((a, b) => a.id - b.id)
            //
            //     currentUpdateData = {
            //         id: currentActiveData?.id,
            //         guid: currentActiveData?.guid,
            //         image: type === "image" ? data : currentActiveData?.image,
            //         categories: currentActiveData?.categories,
            //         title: currentActiveData?.title,
            //         styles: currentActiveData?.styles,
            //     }
            // } else if (type === "category") {
            //     const pattern = /\/product\/(\d+)/;
            //     const match = action === "remove" ? null : data?.match(pattern);
            //     const productId = match?.[1];
            //
            //
            //     // Обновление доп категории
            //     if (action === "remove") {
            //         // Условие для удаления категорий с массива
            //         const findCurrentId = currentActiveData?.categories.filter(item => item != data)
            //
            //         if (!findCurrentId) {
            //             alert("Произошла ошибка!")
            //         }
            //
            //         newData = [
            //             ...savePrevData,
            //             {
            //                 id: currentActiveData?.id,
            //                 guid: currentActiveData?.guid,
            //                 image: currentActiveData?.image,
            //                 categories: findCurrentId,
            //                 title: currentActiveData?.title,
            //                 styles: currentActiveData?.styles,
            //             }
            //         ].sort((a, b) => a.id - b.id)
            //
            //         setCategoryIdsData(findCurrentId)
            //
            //         currentUpdateData = {
            //             id: currentActiveData?.id,
            //             guid: currentActiveData?.guid,
            //             image: currentActiveData?.image,
            //             categories: findCurrentId,
            //             title: currentActiveData?.title,
            //             styles: currentActiveData?.styles,
            //         }
            //     } else if (action === "add") {
            //         if (match) {
            //             // Условие для добавления категорий в массива
            //             newData = [
            //                 ...savePrevData,
            //                 {
            //                     id: currentActiveData?.id,
            //                     guid: currentActiveData?.guid,
            //                     image: currentActiveData?.image,
            //                     categories: [...currentActiveData?.categories, productId],
            //                     title: currentActiveData?.title,
            //                     styles: currentActiveData?.styles,
            //                 }
            //             ].sort((a, b) => a.id - b.id)
            //         }
            //
            //
            //         setCategoryIdsData([...currentActiveData?.categories, productId])
            //
            //         currentUpdateData = {
            //             id: currentActiveData?.id,
            //             guid: currentActiveData?.guid,
            //             image: currentActiveData?.image,
            //             categories: [...currentActiveData?.categories, productId],
            //             title: currentActiveData?.title,
            //             styles: currentActiveData?.styles,
            //         }
            //     }
            // } else if (type === "heading") {
            //     newData = [
            //         ...savePrevData,
            //         {
            //             id: currentActiveData?.id,
            //             guid: currentActiveData?.guid,
            //             image: currentActiveData?.image,
            //             categories: currentActiveData?.categories,
            //             title: data,
            //             styles: currentActiveData?.styles,
            //         }
            //     ].sort((a, b) => a.id - b.id)
            //
            //     currentUpdateData = {
            //         id: currentActiveData?.id,
            //         guid: currentActiveData?.guid,
            //         image: currentActiveData?.image,
            //         categories: currentActiveData?.categories,
            //         title: data,
            //         styles: currentActiveData?.styles,
            //     }
            // } else if (type === "position") {
            //     newData = [
            //         ...savePrevData,
            //         {
            //             id: currentActiveData?.id,
            //             guid: currentActiveData?.guid,
            //             image: currentActiveData?.image,
            //             categories: currentActiveData?.categories,
            //             title: currentActiveData?.title,
            //             styles: data,
            //         }
            //     ].sort((a, b) => a.id - b.id)
            //
            //     currentUpdateData = {
            //         id: currentActiveData?.id,
            //         guid: currentActiveData?.guid,
            //         image: currentActiveData?.image,
            //         categories: currentActiveData?.categories,
            //         title: currentActiveData?.title,
            //         styles: data,
            //     }
            // } else if (type === "heading_text") {
            //     newData = [
            //         ...savePrevData,
            //         {
            //             id: currentActiveData?.id,
            //             guid: currentActiveData?.guid,
            //             image: currentActiveData?.image,
            //             categories: currentActiveData?.categories,
            //             title: {
            //                 ...currentActiveData?.title,
            //                 text: {
            //                     ...currentActiveData?.title?.text,
            //                     ...data
            //                 },
            //             },
            //             styles: data,
            //         }
            //     ].sort((a, b) => a.id - b.id)
            //
            //     currentUpdateData = {
            //         id: currentActiveData?.id,
            //         guid: currentActiveData?.guid,
            //         image: currentActiveData?.image,
            //         categories: currentActiveData?.categories,
            //         title: {
            //             ...currentActiveData?.title,
            //             text: {
            //                 ...currentActiveData?.title?.text,
            //                 ...data
            //             },
            //         },
            //         styles: data,
            //     }
            // } else if (type === "heading_styles") {
            //     newData = [
            //         ...savePrevData,
            //         {
            //             id: currentActiveData?.id,
            //             guid: currentActiveData?.guid,
            //             image: currentActiveData?.image,
            //             categories: currentActiveData?.categories,
            //             title: {
            //                 ...currentActiveData?.title,
            //                 styles: {
            //                     ...currentActiveData?.title?.styles,
            //                     ...data,
            //                 },
            //             },
            //             styles: currentActiveData?.styles,
            //         }
            //     ].sort((a, b) => a.id - b.id)
            //
            //     currentUpdateData = {
            //         id: currentActiveData?.id,
            //         guid: currentActiveData?.guid,
            //         image: currentActiveData?.image,
            //         categories: currentActiveData?.categories,
            //         title: {
            //             ...currentActiveData?.title,
            //             styles: {
            //                 ...currentActiveData?.title?.styles,
            //                 ...data,
            //             },
            //         },
            //         styles: currentActiveData?.styles,
            //     }
            // }

            // setStoriesData(newData)
            // setActiveStories(currentUpdateData)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * @author Zholaman Zhumanov
     * @description Метод для удаления сториса по ID
     * @param id
     */
    const deleteStores = (id) => {
        try {
            const savePrevData = storiesData?.filter((stories) => stories?.guid !== id)

            setStoriesData([...savePrevData])
        } catch (error) {
            console.error(error)
        }
    }

    const clearStories = () => {
        try {
            changeParams("stories", [], null)
            setStoriesData([])
            setActiveStories([])
            setCategoryIdsData([])
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * @author Zholaman Zhumanov
     * @description Метод для добавления нового блока для сториса
     * @type {(function(): (void|undefined))|*}
     */
    const addStories = useCallback(() => {
        const findCurrentData = storiesData?.[storiesData?.length - 1]

        if (!findCurrentData) {
            setStoriesData([
                {
                    guid: uuid(),
                    id: 1,
                    image: {
                        "url": ThumbnailImage
                    },
                    categories: [],
                    title: "",
                    styles: {
                        justifyContent: 'center',
                    },
                }
            ])

            setActiveStories({
                guid: uuid(),
                id: 1,
                image: {
                    "url": ThumbnailImage
                },
                categories: [],
                title: "",
                styles: {
                    justifyContent: 'center',
                },
            })

            return;
        }

        setStoriesData(prev => {
            return [
                ...prev,
                {
                    guid: uuid(),
                    id: findCurrentData?.id + 1,
                    image: {
                        "url": ThumbnailImage
                    },
                    categories: [],
                    title: "",
                    styles: {
                        justifyContent: 'center',
                    },
                },
            ]
        })
    }, [storiesData])

    useEffect(() => {
        if (swiper) {
            setActiveStories(storiesData?.[swiper.activeIndex])
            setCategoryIdsData(storiesData?.[swiper.activeIndex]?.categories)

            setUpdateOptions(false)

        }

        timerUpdateOptionsRef.current = setTimeout(() => {
            setUpdateOptions(true)
        }, 5)
        return () => {
            clearTimeout(timerUpdateOptionsRef.current)
            setSwiper(null)
        }
    }, [swiper, storiesData])

    return (
        <>
            <div className={styled.stories_container}>
                <div className={styled.stories_slider}>
                    <Swiper
                        autoplay={false}
                        loop={false}
                        onSwiper={swiper => {
                            setSwiper(swiper)
                            setActiveStories(storiesData[swiper.activeIndex])
                        }}
                        onSlideChange={swiper => {
                            setSwiper(swiper)
                            setActiveStories(storiesData?.[swiper.activeIndex])
                            setCategoryIdsData(storiesData?.[swiper.activeIndex]?.categories)
                        }}
                    >
                        {
                            storiesData.map((story) => {
                                return (
                                    <SwiperSlide key={story.guid}>
                                        <div style={{width: "100%", position: "relative"}}>
                                            <img
                                                src={story.image?.url}
                                                alt={`image-index-${story.guid}`}
                                                className={styled.img_slider}
                                            />
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    position: "absolute",
                                                    top: "0px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    ...story?.styles
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        ...story?.title?.styles,
                                                        margin: `${story?.title?.styles?.margin?.join(" ")}`
                                                    }}>{story?.title?.text?.[lang]}</div>
                                            </div>
                                            <Button
                                                style={{
                                                    backgroundColor: "#ff4d4f",
                                                    position: "absolute",
                                                    top: "10px",
                                                    right: "10px",
                                                    zIndex: 10
                                                }}
                                                onClick={() => deleteStores(story.guid)}>
                                                <DeleteOutlined/>
                                            </Button>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
                <div className={styled.stories_tools}>
                    <div className={styled.tools_box}>
                        <div className={styled.stories_actions}>
                            <Button
                                onClick={addStories}
                                style={{backgroundColor: "#a0d911"}}>
                                <PlusOutlined/> Добавить
                                сторис
                            </Button>
                            <Button
                                style={{backgroundColor: "#ff4d4f"}}
                                onClick={() => clearStories()}>
                                <DeleteOutlined/> Очистить весь сторис
                            </Button>
                        </div>

                        <Divider/>

                        <Button
                            onClick={toggleFileManagerModal}
                            type={"default"}
                        >
                            <FileImageOutlined/>
                            Добавить картинку
                        </Button>

                        <Button
                            type={"default"}
                            onClick={() => updateStories(text(langList), !activeStories?.title ? "heading" : "heading_remove")}
                        >
                            <div className={styledComponent.inner}>
                                <div className={styledComponent.innerText}>
                                    <FontSizeOutlined/> {activeStories?.title ? `Удалить заголовок` : "Добавить заголовок"}
                                </div>
                            </div>
                        </Button>

                        <div className={styled.categories_box}>
                            <h4 className={styled.label}>Категории для товара</h4>
                            <div className={styled.input}>
                                <Input
                                    value={categoryIds}
                                    onChange={(e) => {
                                        setCategoryIds(e.target.value)
                                    }}
                                    placeholder={"Введите ссылку товара"}
                                />
                                <Button
                                    onClick={() => {
                                        updateStories(categoryIds, "category", "add")
                                        setCategoryIds("")
                                    }}
                                    type={"text"}
                                >
                                    Добавить
                                </Button>
                            </div>
                            <Divider/>
                            <ul className={styled.categories_list}>
                                {
                                    categoryIdsData?.map(category => {
                                        return (
                                            <li>
                                                {category}
                                                <Button
                                                    type={"text"}
                                                    onClick={() => updateStories(category, "category", "remove")}>
                                                    <span><CloseOutlined/></span>
                                                </Button>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            {
                                updateOptions && (
                                    <>
                                        <SettingText
                                            text={activeStories?.title?.text}
                                            updateCallback={(data) => {
                                                updateStories(data, "heading_text")
                                            }}
                                        />

                                        <SettingStyles
                                            styles={activeStories?.title?.styles}
                                            updateCallback={(data) => {
                                                updateStories(data, "heading_styles")
                                            }}
                                        />

                                        <SettingStylesMain
                                            updateKey={"position"}
                                            styles={activeStories?.styles}
                                            updateCallback={(data) => {
                                                updateStories(data, "position")
                                            }}
                                        />
                                    </>
                                )
                            }
                        </div>
                    </div>

                    <ModalFileManager
                        closeModalWhenConfirm={true}
                        open={modalFileManager}
                        handleClose={toggleFileManagerModal}
                        setNewImage={(image) => {
                            updateStories(image, 'image')
                        }}
                    />
                </div>
            </div>
            <div className={styled.footer}>
                <Button
                    type={"ghost"}
                    size={"large"}
                    onClick={() => {
                        changeParams("stories", storiesData, null)
                        toggleModal()
                        setStoriesData([])
                    }}
                >
                    Сохранить
                </Button>
            </div>
        </>

    )
};

export default StoriesSlider;
