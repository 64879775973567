import Player from "react-player";
import React, { useEffect, useState } from "react";
import { setMessages } from "../../../redux/reducers/errorsReducer";
import { useDispatch } from "react-redux";
import BorderActive from "./BorderActive";
import styled from "../../../styles/editorPage/workField.module.scss";
import { IsReplacementComponent } from "../components/Blocks/lib/IsReplacementComponent";
import { useBorderActive } from "../../../hooks/useBorderActive";
import { useNavigate } from "react-router";

export default function DashPlayer({src, poster, autoplay, component, mainId, isActivePage}) {
	// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

	const [mouseOver, setMouseOver] = useState(false)
	const [loading, setLoading] = useState(false)

	let navigate = useNavigate();

	const {
		width, height, isHide, ref,
		idActive, selectThisComponent,
		resize
	} = useBorderActive(component, [mainId, component?.id], true)

	const dispatch = useDispatch()

	const setAnimate = (flag) => {
		if (component?.hover?.hoverText) {
			setMouseOver(flag)
		}
	}

	const handleLoaded = () => {
		setLoading(true)
/*		dispatch(setMessages([]))*/
	}
	const handleError = () => {
		setLoading(false)
/*		dispatch(setMessages(['Некорректная ссылка на видео']))*/
	}
/*
	const setMessagesRedux = (val) => {
		dispatch(setMessages(val))
	}
	*/
	const setActiveOrLinkTo = (event) => {
		if (!isHide) {
			resize()
			selectThisComponent(event)
		} else {
			if (component?.link?.isActive) {
				if (component?.link?.out) {
					window.open(component.link.path)
				} else {
					let arr = component.link.path.split('.com')
					navigate(arr[1].slice(7))
				}
			}
		}
	}


	useEffect(() => {

	}, [loading])

	return (

		<div className={'p-relative d-block w-100'} style={{height: '100vh'}}>
			<div style={{
				position: 'absolute',
				height: '100%',
				width: '100%',
				top: 0,
				left: 0,
				zIndex: 1
			}}>
				{!isHide && idActive.length === 2 && idActive[1] === component?.id && !isActivePage &&
					<BorderActive
						width={width}
						height={height}
						btnDown={true}
						id={[mainId, component.id]}
					/>
				}
				<div
					className={styled.itemContainer}
					ref={ref}
					onClick={setActiveOrLinkTo}
					onMouseOver={() => setAnimate(true)}
					onMouseOut={() => setAnimate(false)}
				>
					<div
						className={`${styled.frontSpace} ${component?.hover?.hoverBackground ? styled.hoverBackground : ''}`}
						style={{
							justifyContent: component?.styles?.justifyContent,
						}}
					>
						{component?.components?.map((item, key) =>
							<React.Fragment key={key}>
								<IsReplacementComponent
									item={item}
									mainId={mainId}
									component={component}
									mouseOver={mouseOver}
									isActivePage={isActivePage}
								/>
							</React.Fragment>
						)}
					</div>
				</div>
			</div>
			<div className={loading ? 'poster-video hide' : 'poster-video'}>
				<img
					src={poster}
					alt={'здесь должно быть видео)'}
					style={{
						width: '100%',
						height: '100vh',
						objectFit: 'cover'
					}}
					className={loading ? 'img-opacity-animate active' : 'img-opacity-animate'}
				/>
			</div>
			<Player
				url={src}
				loop={true}
				playsinline={true}
				controls={false}
				muted
				playing={autoplay}
				autoPlay={autoplay}
				onReady={() => {
					handleLoaded()
				}}
				onError={() => {
					handleError()
				}}
				width={'100%'}
				height={'100%'}
				style={{
					objectFit: 'cover',
					position: "relative",
					padding: '0',
					marginBottom: '-5px',
				}}
			/>
		</div>
	);
}
