import React from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import { Input } from "antd";

const SettingSliderDelay = ({autoplay, changeParams, isReplacement}) => {
  return (
    <React.Fragment>
      {autoplay
        ?
        <div>
          <div className={styled.textAndButtoms} style={{marginTop: "10px", marginBottom: "10px"}}>
            <div className={styled.label}>
              Задержка
            </div>
            <div className={`${styled.buttomItems}`}>
              <Input
                type="number"
                style={{ width: '75px' }}
                size={'small'}
                defaultValue={autoplay.delay}
                onChange={(e) => changeParams('autoplay', { delay: Number(e.target.value) }, isReplacement)}
              />
            </div>
          </div>
          <div className={styled.textAndButtoms} style={{marginBottom: "10px"}}>
            <div className={styled.label}>
              Скорость
            </div>
            <div className={`${styled.buttomItems}`}>
              <Input
                type="number"
                style={{ width: '75px' }}
                size={'small'}
                defaultValue={autoplay.speed}
                onChange={(e) => changeParams('autoplay', { speed: Number(e.target.value) }, isReplacement)}
              />
            </div>
          </div>
        </div>
        :
        <></>
      }
    </React.Fragment>
  );
};


export default SettingSliderDelay;