import React, { useState} from 'react';
import styled from '../../styles/modals/modals.module.scss'
import {useDispatch} from "react-redux";
import {setComponentCollection} from "../../redux/reducers/editorReducer";
import {getInitialStyles, getInitialStylesSW} from "../../helpers/initialStylesForComponents";
import { Form, InputNumber, Modal } from 'antd';


export default function ModalCountHalfSlider({open, handleClose,  langList}) {

	const [count, setCount] = useState(1);

	const dispatch = useDispatch();

	const createNewComponent = () => {
		dispatch(setComponentCollection(getInitialStylesSW("block-slider-image", count, langList)))
		handleClose()
	}



	const chageCount = (e, val) => {
		if (e?.keyCode == 13) {
			e.preventDefault()
			createNewComponent()
		}
		setCount(val)
	}

	return (
		<div>
			<Modal
				visible={open}
				onOk={createNewComponent}
				onCancel={handleClose}
				title={"Добавить новый компонент"}
				okText="Добавить"
				cancelText="Отмена"
				closeIcon={<span className="f-icon icon-cross fs-17"/>}
			>
				<form noValidate autoComplete="off" onKeyDown={chageCount}>
					<div className={styled.inputLarge}>
						<Form.Item
							label="Укажите кол-во блоков в слайдере"
						>
						<InputNumber
							value={count}
							onChange={(val) => chageCount(null, val)}
							style={{ width: 200 }}
							default
						/>
						</Form.Item>
					</div>
				</form>
			</Modal>

		</div>
	);
}