import React, {useEffect, useState} from 'react';
import {
	setActiveComponent,
	setDeleteActiveComponent,
	setDeleteSwiperComponent,
	setPosition
} from "../../../redux/reducers/editorReducer";
import { useDispatch, useSelector } from "react-redux";
import { getActiveComponent } from "../../../redux/selectors/editorSelectors";

const BorderActive = ({width, height, id, btnDown, large, container, blockSwiper, collection}) => {

	const activeComponent = useSelector(state => getActiveComponent(state))

	const [styles, setStyles] = useState({})

	const dispatch = useDispatch()


	useEffect(() => {
		if (width && height) {
			if (!large) {
				setStyles({
					width: `${width}px`,
					height: `${height}px`,
					border: '5px solid #1890ff',
					position: 'absolute',
					zIndex: 9,
					pointerEvents: 'none'
				})
			} else {
				setStyles({
					width: `${width + 14}px`,
					height: `${height + 14}px`,
					border: '5px solid #1890ff',
					position: 'absolute',
					zIndex: 9,
					top: '-7px',
					left: '-7px',
					pointerEvents: 'none'
				})
			}
		} else {
			setStyles({})
		}
	}, [width, height])

	const resetActive = () => {
		dispatch(setActiveComponent([]))
	}
	const deleteComponent = () => {
		dispatch(setActiveComponent([]))
		dispatch(setDeleteActiveComponent(id))
	}
	const deleteComponentSwiper = () => {
		dispatch(setActiveComponent([]))
		dispatch(setDeleteSwiperComponent(id))
	}

	const changePosition = (direction) => {
		dispatch(setPosition(direction))
	}

	return (
		<div style={styles}>
			<div
				style={{
					position: "absolute",
					top: !btnDown ? "-40px" : '-5px',
					right: '0px',
					zIndex: 1,
					pointerEvents: 'auto',
					opacity: 1,
					display: 'flex'
				}}
			>
				{id && !blockSwiper &&
				<div style={{padding: '10px 10px'}}>
					<span className="f-icon icon-trash red fs-13" onClick={deleteComponent}/>
				</div>
				}
				{id && blockSwiper &&
				<div style={{padding: '10px 10px'}}>
					<span className="f-icon icon-trash red fs-13" onClick={deleteComponentSwiper}/>
				</div>
				}
				{container &&
				<>
					{collection && collection[0].id !== activeComponent[activeComponent.length - 1] &&
					<div style={{padding: '10px 5px'}}>
						<span className="f-icon icon-chevron-up fs-13" 	onClick={() => changePosition('up')} style={{color: '#ffffff'}}/>
					</div>
					}
					{collection && collection[collection.length - 1].id !== activeComponent[activeComponent.length - 1] &&

					<div style={{padding: '10px 5px'}}>
						<span className="f-icon icon-chevron-down fs-13" 	onClick={() => changePosition('down')} style={{color: '#ffffff'}}/>
					</div>
					}
				</>
				}
				<div style={{padding: '10px 5px'}}>
					<span className="f-icon icon-cross-circle fs-13" 	onClick={resetActive} style={{color: '#1890ff'}}/>
				</div>
			</div>
		</div>
	);
};

export default BorderActive;