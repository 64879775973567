import React from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import { Checkbox, Form, Input, Select } from "antd";
import ReactDOM from "react-dom/client";
import { Option } from "antd/es/mentions";

const SettingTypeBlock = ({type, changeParams, isReplacement}) => {

  const changeType = (value) => {
      changeParams('type', value, isReplacement)
    console.log(value)
  }

  return (
    <React.Fragment>
      {type
        ?
        <div>
          <div className={styled.textAndInput}>
            <div className={styled.label}>
              Тип блока:
            </div>
            <Select
              style={{
                width: 350,
              }}
              defaultValue={type}
              onChange={changeType}
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Option value="video">Видео</Option>
              <Option value="image">Изображение</Option>
            </Select>
          </div>
        </div>
        :
        <></>
      }
    </React.Fragment>
  );
};


export default SettingTypeBlock;