import React from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import { Checkbox, Form, Input } from "antd";

const SettingTheme = ({ blockTheme, changeParams, isReplacement }) => {

  const changeActive = (checked) => {
    changeParams('blockTheme', checked, isReplacement)
  }

  return (
    <React.Fragment>
      {(blockTheme !== undefined) &&
        <React.Fragment>
          <div className={styled.textAndInput}>
            <div className={styled.label}>
              Тема:
            </div>
          </div>

          <div className={`${styled.blockThemeContainer}`}>
              <Checkbox
                checked={blockTheme}
                onChange={(e) => changeActive(e.target.checked)}
              >
                Темная тема
              </Checkbox>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};


export default SettingTheme;