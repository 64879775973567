import React from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'

const SettingAnimation = ({hover, animate, changeParams, isReplacement}) => {

	const setHover = (params) => {
		changeParams('hover', params, isReplacement)
	}

	const setAnimate = (params) => {
		changeParams('animate', params, isReplacement)
	}

	return (
		<div>
			{(hover || animate)
				?
				<div className={styled.animate}>
					<h3>Анимация</h3>
					<div className={styled.blocks}>
						{ hover &&
							<React.Fragment>
								{Object.entries(hover).map((item, key) =>
									<React.Fragment key={key}>
										{item[0] === 'hoverBackground' ?
											<div
												className={`${styled.block} ${item[1] ? styled.blockActive : ''}`}
												onClick={() => setHover({[item[0]]: !item[1]})}
											>
												<div className={styled.text}>Фон</div>
												<div className={styled.photoBG}/>
											</div>
											///АНИМАЦИЯ ТЕКСТА
											/*	: item[0] === 'hoverText' ?
											 <div
											 className={`${styled.block} ${item[1] ? styled.blockActive : ''}`}
											 onClick={() => setAnimate({[item[0]]: !item[1]})}
											 >
											 <div className={styled.text}>Текст</div>
											 <div className={styled.photoTX}>
											 <div className={styled.TX}>text</div>
											 </div>
											 </div>*/
											:
											<></>
										}
									</React.Fragment>
								)}
							</React.Fragment>
						}
						{ animate &&
							<React.Fragment>
								{Object.entries(animate).map((item, key) =>
									<React.Fragment key={key}>
										{item[0] === 'appearanceImage' ?
											<div
												className={`${styled.block} ${item[1] ? styled.blockActive : ''}`}
												onClick={() => setAnimate({[item[0]]: !item[1], appearanceImageIncrease: false})}
											>
												<div
													className={styled.text}
													style={{fontSize: "11px"}}
												>
													Появление <span style={{textWrap: "nowrap"}}>(decrease)</span>
												</div>
												<div className={styled.photoBG}/>
											</div>
											: item[0] === 'appearanceImageIncrease' ?
												<div
													className={`${styled.block} ${item[1] ? styled.blockActive : ''}`}
													onClick={() => setAnimate({[item[0]]: !item[1], appearanceImage: false})}
												>
													<div
														className={styled.text}
														style={{fontSize: "11px"}}
													>
														Появление <span style={{textWrap: "nowrap"}}>(increase)</span>
													</div>
													<div className={styled.photoBGIncrease}/>
												</div>
												: item[0] === 'stickyTextWithGradient' ?
												<div
													className={`${styled.block} ${item[1] ? styled.blockActive : ''}`}
													onClick={() => setAnimate({[item[0]]: !item[1]})}
												>
													<div className={styled.text} style={{fontSize: "12px"}}>
														Фикс текст
													</div>
													<div className={styled.photoTX}>
														<div className={styled.TX}>text</div>
													</div>
												</div>
												: item[0] === 'showFlash' ?
													<div
														className={`${styled.block} ${item[1] ? styled.blockActive : ''}`}
														onClick={() => setAnimate({[item[0]]: !item[1]})}
													>
														<div className={styled.text} style={{fontSize: "11px"}}>
															Анимация (Flash)
														</div>
														<div className={`${styled.photoBgStatic} ${styled.flash}`}>

														</div>
													</div>
												:
												<></>
										}
									</React.Fragment>
								)}
							</React.Fragment>
						}
					</div>
				</div>
				:
				<></>
			}
		</div>
	);
};

export default SettingAnimation;