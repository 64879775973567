import React from 'react';
import { Radio } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setThemeAction } from "../../redux/reducers/editorReducer";
import { getTheme } from "../../redux/selectors/editorSelectors";

const ThemeSwitcher = () => {
  const theme = useSelector(state => getTheme(state));
  const dispatch = useDispatch()

  const changeTheme = (value) => {
    dispatch(setThemeAction(value))
  }

  return (
    <div>
      <Radio.Group onChange={(e) => changeTheme(e.target.value)} value={theme} >
        <Radio.Button value="light"><span className="icon-sun fs-15"/></Radio.Button>
        <Radio.Button value="dark"><span className="icon-moon fs-15"/></Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default ThemeSwitcher;