//Templates Reducer
export const ADD_TEMPLATE_LIST = "ADD_TEMPLATE_LIST"
export const CHANGE_ACTIVE_PAGE = "CHANGE_ACTIVE_PAGE"
export const CHANGE_PAGINATION_DATA = "CHANGE_PAGINATION_DATA"

//Images Reducer
export const ADD_HIERARCHY = "ADD_HIERARCHY"
export const ADD_FILES = "ADD_FILES"

//Editor Reducer
export const CHANGE_HIDE = "CHANGE_HIDE"
export const CHANGE_THEME = "CHANGE_THEME"
export const CHANGE_LANG = "CHANGE_LANG"
export const CHANGE_LANG_LIST = "CHANGE_LANG_LIST"
export const CHANGE_RIGHTBAR_MENU = "CHANGE_RIGHTBAR_MENU"
export const CHANGE_NAME_PAGE = "CHANGE_NAME_PAGE"
export const CHANGE_DEVICE = "CHANGE_DEVICE"
export const ADD_COMPONENT_COLLECTION = "ADD_COMPONENT_COLLECTION"
export const CHANGE_ACTIVE_COMPONENT = "CHANGE_ACTIVE_COMPONENT"
export const CHANGE_POSITION = "CHANGE_POSITION"
export const CHANGE_STYLES_ACTIVE_COMPONENT = "CHANGE_STYLES_ACTIVE_COMPONENT"
export const DELETE_ACTIVE_COMPONENT = "DELETE_ACTIVE_COMPONENT"
export const DELETE_SWIPER_COMPONENT = "DELETE_SWIPER_COMPONENT"
export const DELETE_KEY_OBJECT = "DELETE_KEY_OBJECT"
export const CHANGE_AUTOPLAY = "CHANGE_AUTOPLAY"
export const ADD_BLOCK = "ADD_BLOCK"
export const ADD_BLOCK_SWIPER = "ADD_BLOCK_SWIPER"
export const ADD_ELEMENT = "ADD_ELEMENT"
export const ADD_ELEMENT_REPLACEMENT = "ADD_ELEMENT_REPLACEMENT"
export const ADD_SLICED = "ADD_SLICED"
export const ADD_SAVE_COLLECTION = "ADD_SAVE_COLLECTION"
export const SET_IMAGE_FULL = "SET_IMAGE_FULL"
export const COPY_PAGE_MOBILE = "COPY_PAGE_MOBILE"

//Errors Reducer
export const CHANGE_MESSAGES = "CHANGE_MESSAGES"

//update
export const UPDATE_COLLECTION = "UPDATE_COLLECTION"

// Modal
export const STORIES_MODAL = 'STORIES_MODAL'
export const PAGE_TYPE = 'PAGE_TYPE'
