import React from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import {Radio, Tooltip} from 'antd';
import centerIco from '../../../../assets/sprite/center.png'
import centerIcoActive from '../../../../assets/sprite/center.active.png'
import endActiveIco from '../../../../assets/sprite/flex-end.active.png'
import endIco from '../../../../assets/sprite/flex-end.png'
import startActiveIco from '../../../../assets/sprite/flex-start.active.png'
import startIco from '../../../../assets/sprite/flex-start.png'
import aroundActiveIco from '../../../../assets/sprite/space-around.active.png'
import evenlyIco from '../../../../assets/sprite/space-evenly.png'
import evenlyActiveIco from '../../../../assets/sprite/space-evenly.active.png'
import betweenActiveIco from '../../../../assets/sprite/space-bettwen.active.png'
import betweenIco from '../../../../assets/sprite/space-bettwen.png'
import aroundIco from '../../../../assets/sprite/space-around.png'


const SettingStylesMain = ({styles, changeParams, isReplacement, updateKey, updateCallback}) => {
    const tooltip = {
        center: 'Элементы располагаются друг за другом в центре контейнера по главной оси.',
        end: 'Элементы располагаются друг за другом и прижимаются к концу контейнера по главной оси.',
        start: 'Элементы располагаются друг за другом и прижимаются к началу контейнера по главной оси',
        between: 'Элементы равномерно распределены вдоль главной оси контейнера. Расстояния между каждой парой соседних элементов равны. Первый элемент прижат к началу контейнера по главной оси, а последний - к концу.',
        around: 'Элементы равномерно распределены вдоль главной оси контейнера. Расстояния между каждой парой соседних элементов равны. Пустые пространства перед первым элементом и после последнего элемента равны половине расстояния между парами соседних элементов.',
        evenly: 'Элементы равномерно распределены вдоль главной оси контейнера. Расстояния между каждой парой соседних элементов равны расстояниям от начала контейнера до первого элемента и от последнего элемента до конца контейнера.',
    }

    const getTranslate = (val) => {
        if (val === "center") return 'по центру'
        if (val === "start") return 'по верху'
        if (val === "end") return 'по низу'
        if (val === "space-between") return 'равномерно (макс.)'
        if (val === "space-around") return 'равномерно'
        if (val === "space-evenly") return 'равномерно (мин.)'
    }

    return (
        <div>
            {styles
                ?
                <div>
                    {Object.entries(styles).map((item, key) =>
                        <div key={key}>
                            {item[0] === 'justifyContent'
                                ?
                                <div className={styled.mainStyles}>

                                    <div className={styled.title}>
                                        Расположение
                                    </div>
                                    <div className={styled.buttons}>
                                        <div className={'custom_SSM'}>
                                            <Radio.Group
                                                onChange={(e) => {
                                                    if (changeParams) changeParams('styles', {[item[0]]: e.target.value}, isReplacement)
                                                    if (updateCallback) updateCallback({[item[0]]: e.target.value})
                                                }}
                                                value={item[1]}
                                            >
                                                <Tooltip title={tooltip.center}>
                                                    <Radio.Button value="center" className={styled.rButton}><img
                                                        src={item[1] === "center" ? centerIcoActive : centerIco}
                                                        alt=""/></Radio.Button>
                                                </Tooltip>
                                                <Tooltip title={tooltip.start}>
                                                    <Radio.Button value="start" className={styled.rButton}><img
                                                        src={item[1] === "start" ? startActiveIco : startIco}
                                                        alt=""/></Radio.Button>
                                                </Tooltip>
                                                <Tooltip title={tooltip.end}>
                                                    <Radio.Button value="end" className={styled.rButton}><img
                                                        src={item[1] === "end" ? endActiveIco : endIco}
                                                        alt=""/></Radio.Button>
                                                </Tooltip>
                                                <Tooltip title={tooltip.between}>
                                                    <Radio.Button value="space-between" className={styled.rButton}><img
                                                        src={item[1] === "space-between" ? betweenActiveIco : betweenIco}
                                                        alt=""/></Radio.Button>
                                                </Tooltip>
                                                <Tooltip title={tooltip.around}>
                                                    <Radio.Button value="space-around" className={styled.rButton}><img
                                                        src={item[1] === "space-around" ? aroundActiveIco : aroundIco}
                                                        alt=""/></Radio.Button>
                                                </Tooltip>
                                                <Tooltip title={tooltip.evenly}>
                                                    <Radio.Button value="space-evenly" className={styled.rButton}><img
                                                        src={item[1] === "space-evenly" ? evenlyActiveIco : evenlyIco}
                                                        alt=""/></Radio.Button>
                                                </Tooltip>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    <div className={styled.helpers}>
                                        Центрирование объектов {getTranslate(styles.justifyContent)}
                                    </div>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    )}
                </div>
                :
                <></>
            }
        </div>
    );
};

export default SettingStylesMain;
