import {STORIES_MODAL} from '../types'

let initialState = {
    storiesModal: false,
};

const triggerReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORIES_MODAL:
            return {
                ...state,
                storiesModal: action.storiesModal
            }
        default:
            return state;
    }
}

export const setStoriesModal = (value) => ({type: STORIES_MODAL, value});

export default triggerReducer;
