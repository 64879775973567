import React, {useEffect, useState} from 'react';
import styled from '../../styles/modals/modals.module.scss'
import {Button, Input, message, Modal, Spin, Tree} from "antd";
import {imageAPI} from "../../api/api";
import {useDispatch, useSelector} from "react-redux";
import {getFiles, getFolders} from "../../redux/selectors/imagesSelectors";
import {requestFiles, requestTree} from "../../redux/actions/imagesAction";
import Upload from "antd/es/upload";
import {setMessages} from "../../redux/reducers/errorsReducer";

const {DirectoryTree} = Tree;

const RightModal = (props) => {

	const {
		openRight,
		handleCloseRight,
		nameFolder,
		setNameFolder,
		addFolder,
		activePath,
		setActivePath,
		action,
		deleteFolder
	} = props;

	return (
		<div className={`${styled.rightModal} ${openRight ? styled.right_modal_open : ''}`}>
			<div
				className={styled.hideModal}
				onClick={handleCloseRight}
			>
				<div className={styled.icon}>
					<span className="f-icon icon-chevron-right-circle fs-15"/>
				</div>
				<div className={styled.text}>скрыть</div>
			</div>
			{action === 'create' ?
				<div className={styled.textFieldFolder}>
					<div className={styled.label}>Директория</div>
					<Input
						value={activePath}
						onChange={(e) => setActivePath(e.target.value)}
						style={{width: '300px'}}

					/>
					<div className={styled.label}>Введите название папки</div>
					<Input
						onChange={(e) => setNameFolder(e.target.value)}
						style={{width: '300px'}}

					/>
					<div className={styled.buttonFolder}>
						<Button
							type='primary'
							onClick={addFolder}
						>
							Создать
						</Button>
					</div>
				</div>
				:
				<div className={styled.deleteFolder}>
					<div className={styled.title}>Удаление "{nameFolder}"</div>
					<div className={styled.question}>
						Вы действительно хотите удалить выбранную папку?
					</div>
					<div className={styled.buttons}>
						<Button
							type='primary'
							onClick={(e) => deleteFolder(e, activePath, 'folder')}
						>
							Подтверждаю
						</Button>
					</div>
				</div>
			}

		</div>
	)
}


export default function ModalFileManager({open, handleClose, setNewImage, closeModalWhenConfirm}) {

	const [activePath, setActivePath] = useState('/')
	const [openRight, setOpenRight] = useState(false)
	const [isLoadingMain, setLoadingMain] = useState(false)
	const [nameFolder, setNameFolder] = useState('')
	const [activeImage, setActiveImage] = useState({})
	const [actionRightBar, setActionRightBar] = useState('')
	const hierarchy = useSelector(state => getFolders(state));
	const files = useSelector(state => getFiles(state));


	const closeModal = () => {
		handleClose()
		setOpenRight(false)
	}

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(requestTree())
		dispatch(requestFiles('/'))
	}, [])
	const deleteFolder = async (e, path, type) => {
		e.stopPropagation()
		const {api_success, api_messages} = await imageAPI.deleteFolder(path, type)
		if (api_success) {
			if (type === 'file') {
				dispatch(requestFiles(activePath))
			}
			if (type === 'folder') {
				dispatch(requestFiles('/'))
				setActivePath('/')
				dispatch(requestTree())
				setOpenRight(false)
			}
		}
		setMessagesRedux(api_messages)
	}

	const onSelect = async (keys, info) => {
		/*		console.log('Trigger Select', keys, info);*/
		setLoadingMain(true)
		setActivePath(keys[0])
		setNameFolder(info.node.name)
		await dispatch(requestFiles(keys[0]))
		setLoadingMain(false)
	};


	const addFolder = async () => {
		const {api_messages} = await imageAPI.createFolder(activePath ? activePath : '/', nameFolder)
		setMessagesRedux(api_messages)
		setOpenRight(false)
		dispatch(requestTree())
	}

	const handleCloseRight = () => {
		setOpenRight(false)
	}

	const actionFolder = (action) => {
		setActionRightBar(action)
		handleOpenRight()
	}

	const handleOpenRight = () => {
		setOpenRight(true)
	}

	const sendFile = async (file) => {
		const {api_messages} = await imageAPI.uploadFile(activePath, file)
		setMessagesRedux(api_messages)
		dispatch(requestFiles(activePath))
	}

	const setMessagesRedux = (val) => {
		dispatch(setMessages(val))
	}

	return (
		<div>
			<Modal
				open={open}
				onOk={() => setNewImage(activeImage)}
				onCancel={closeModal}
				title={'Файловый менеджер'}
				width={850}
				closeIcon={<span className="f-icon icon-cross fs-17"/>}
				bodyStyle={{paddingTop: '0'}}
				footer={[
					<div className={styled.footerFM}>

						{/************Футер*************/}

						<div className={styled.left}>
							<div className={'custom-ant mr-10'}>
								<Button
									type="primary" ghost
									icon={<span className="f-icon icon-plus fs-11 mr-7"/>}
									onClick={() => actionFolder('create')}
									className={'fs-13'}
								>
									Создать папку
								</Button>
							</div>
							<div className={'custom-ant'}>
								<Button
									type="danger"
									icon={<span className="f-icon icon-trash fs-13 mr-7"/>}
									onClick={() => actionFolder('delete')}
									className={'fs-13'}
								>
									Удалить папку
								</Button>
							</div>
						</div>
						<div className={styled.right}>
							<div className={'custom-ant'}>
								<Button
									type="primary"
									icon={<span className="f-icon icon-launch fs-13 mr-7"/>}
									onClick={() => {
										setNewImage(activeImage)

										if (closeModalWhenConfirm) closeModal()
									}}
									className={'fs-13'}
								>
									Выбрать файл
								</Button>
							</div>
						</div>
					</div>
				]}
			>

				{/************Шапка*************/}

				<div className={styled.headerFM}>
					<div className={styled.path}>
						Путь: {activePath}
					</div>
					<div className={styled.buttonsHead}>
						<div className={styled.addImage}>
							<Upload
								onChange={(e) => sendFile(e.file.originFileObj)}
								showUploadList={false}
								maxCount={1}
							>
								<div className={'custom-ant'}>
									<Button
										type="primary" ghost
										icon={<span className="f-icon icon-plus fs-11 mr-7"/>}
										className={'fs-13'}
									>
										Загрузить файл
									</Button>
								</div>
							</Upload>
						</div>
					</div>
				</div>
				<div className={styled.borderFM}/>

				{/************Основное*************/}

				<div className={styled.hierarchy}>

					{/************Папки*************/}

					<div className={styled.folders}>
						<DirectoryTree
							multiple
							defaultExpandAll
							onSelect={onSelect}
							treeData={hierarchy}
							switcherIcon={<span className="icon-chevron-down fs-11"/>}
							fieldNames={{
								key: 'path',
								children: 'items',
								title: 'name'
							}}
							icon={<span className="icon-folder fs-11"/>}
						/>
					</div>

					{/************Фото*************/}

					<div className={styled.files}>
						{files.length ?
							files.map((item, key) =>
								<div
									key={key}
									className={`${styled.imageWrapper} ${item.url === activeImage?.url ? styled.active : ''}`}
								>
									<div className={styled.close}>
										<span className="f-icon icon-cross fs-17" onClick={(e) => deleteFolder(e, item.path, 'file')}/>
									</div>
									<div className={`${styled.container}`}>
										<div className={styled.image} onClick={() => setActiveImage({url: item.url, path: item.path, info: item.info})}>
											<img src={item.url} alt=""/>
										</div>
										<div className={styled.name}>
											{item.name}
										</div>
									</div>
								</div>
							)
							:
							<div className={styled.emptyFolder}>
								<div>Папка пуста...</div>
							</div>
						}
						<div className={`${styled.loadingMain} ${isLoadingMain ? '' : 'hide'}`}>
							<div className="example">
								<Spin size={'large'}/>
							</div>
						</div>

					</div>

					{/************Правая модалка*************/}

					<RightModal
						openRight={openRight}
						handleCloseRight={handleCloseRight}
						nameFolder={nameFolder}
						setNameFolder={setNameFolder}
						addFolder={addFolder}
						activePath={activePath}
						setActivePath={setActivePath}
						action={actionRightBar}
						deleteFolder={deleteFolder}
					/>
				</div>
			</Modal>
		</div>
	);
}
