import {editorAPI} from "../../api/api";
import {setPageType, setTemplateList} from "../reducers/templatesReducer";
import {setAddSaveJSON} from "../reducers/editorReducer";

export const setJSON = (id) => {
	return async (dispatch) => {

		const res = await editorAPI.getById(id)
		if (res.api_success) {
			dispatch(setAddSaveJSON(res.page.guid, res.page.schema.desktop, res.page.schema.mobile, res.page.name, !!res.page.active.length));
			dispatch(setPageType(res.page?.meta?.page_type))
		}
	}
}
