import React, {useMemo, useState} from 'react';
import {Divider, Menu} from 'antd';
import {useSelector} from "react-redux";
import AddComponent from "./Add/AddComponent";
import SettingLink from "./Settings/SettingLink";
import SettingText from "./Settings/SettingText";
import SettingView from "./Settings/SettingView";
import AddReplacement from "./Add/AddReplacement";
import SettingVideo from "./Settings/SettingVideo";
import SettingTheme from "./Settings/SettingTheme";
import SettingImages from "./Settings/SettingImages";
import SettingStyles from "./Settings/SettingStyles";
import SettingStories from "./Settings/SettingStories";
import SettingAutoplay from "./Settings/SettingAutoplay";
import SettingAnimation from "./Settings/SettingAnimation";
import SettingDatePiker from "./Settings/SettingDatePiker";
import SettingTypeBlock from "./Settings/SettingTypeBlock";
import SettingStylesMain from "./Settings/SettingStylesMain";
import SettingTextSliced from "./Settings/SettingTextSliced";
import SettingSliderDelay from "./Settings/SettingSliderDelay";
import SettingProgressBar from "./Settings/SettingProgressBar";
import SettingSlidePerView from "./Settings/SettingSlidePerView";
import SettingCategorySlider from "./Settings/SettingCategorySlider";
import SettingTypeHalfSlider from "./Settings/SettingTypeHalfSlider";
import SettingAutoplaySwiper from "./Settings/SettingAutoplaySwiper";
import SettingPaginationSwiper from "./Settings/SettingPaginationSwiper";
import styled from '../../../styles/editorPage/rightBarEditor.module.scss'
import {getActiveComponent, getDevice, getLang} from "../../../redux/selectors/editorSelectors";
import SettingSlidePerGroup from "./Settings/SettingSlidePerGroup";

/**
 * @author Alexey Gomozev
 * @last-updated
 * @todo refactoring
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SideStyles = (props) => {
    const {
        data,
        dataReplacement,
        changeParams,
        setMenuActive,
        parentComponent,
        parentStylesTextSliced
    } = props;

    const itemsMenu = ['general', 'components', 'other']
    const [activeMenu, setActiveMenu] = useState('general')
    const [disabledItems, setDisabledItems] = useState([])
    const [rerender, setRerender] = useState(0)
    const activeComponent = useSelector(state => getActiveComponent(state));
    const lang = useSelector(state => getLang(state));
    const device = useSelector(state => getDevice(state));

    const dataActual = useMemo(() => {
        if (dataReplacement && (dataReplacement?.deadline || dataReplacement?.isPreview)) {
            if (activeComponent.length === 4) {
                const a = dataReplacement[lang].filter(item => item.id === activeComponent[3])[0]
                return a
            }
            return dataReplacement
        }
        return data
    }, [dataReplacement, data])

    const getDisabledComponents = useMemo(() => {
        return dataActual?.name === 'block' ||
            dataActual?.name === 'block-image' ||
            dataActual?.name === 'swiper_phone' ||
            dataActual?.name === 'block-slider-full' ||
            dataActual?.name === 'block-slider' ||
            dataActual?.name === 'text_sliced' ||
            dataActual?.name === 'text' ||
            dataActual?.name === 'block_text_bg' ||
            dataActual?.name === 'button_outline' ||
            dataActual?.name === 'button_underline' ||
            dataActual?.name === 'countdown_timer' ||
            dataActual?.name === 'stories' ||
            dataActual?.name === 'block_outside'
    }, [dataActual])

    const getDisabledGeneral = useMemo(() => {
        return !dataActual?.image
            && !dataActual?.datePiker
            && !dataActual?.styles?.justifyContent
            && !dataActual?.link
            && !dataActual?.video
            && !dataActual?.view
            && !dataActual?.country
    }, [dataActual])

    const defaultKey = useMemo(() => {
        let arrDisabled = [];

        if (getDisabledGeneral) {
            arrDisabled = [...arrDisabled, 'general']
        }
        if (!getDisabledComponents) {
            arrDisabled = [...arrDisabled, 'components']
        }
        if (!dataActual?.text && !dataActual?.styles && !dataActual?.hover) {
            arrDisabled = [...arrDisabled, 'other']
        }
        setDisabledItems(arrDisabled)
        if (arrDisabled.length < 3) {
            for (let i of itemsMenu) {
                if (!arrDisabled.includes(i)) {
                    setActiveMenu(i)
                    return i
                }
            }
        }
        setRerender(Math.random())
        return ''

    }, [activeComponent])

    const getKey = (key) => {
        if (activeComponent.length) {
            return activeComponent[activeComponent.length - 1] + key
        }
        return key
    }

    return (
        <div style={{marginBottom: '50px'}}>
            <div className={styled.sideHead}>
                <div className={styled.title}>Свойства компонента</div>
                <div className={styled.close} onClick={setMenuActive}><span
                    className="f-icon icon-cross fs-17 hover-blue "/></div>
            </div>
            <Menu
                mode="horizontal"
                defaultSelectedKeys={itemsMenu}
                onSelect={({key}) => setActiveMenu(key)}
                selectedKeys={[activeMenu]}
                style={{marginRight: '10px'}}
            >
                <Menu.Item key="general" disabled={disabledItems.includes("general")}>
                    Общие
                </Menu.Item>
                <Menu.Item key="components" disabled={disabledItems.includes("components")}>
                    Компоненты
                </Menu.Item>
                <Menu.Item key="other" disabled={disabledItems.includes("other")}>
                    Другое
                </Menu.Item>
            </Menu>
            <div style={{marginBottom: '20px'}}/>
            {activeMenu === 'general' ?
                <>
                    <SettingTypeBlock
                        type={dataActual?.type}
                        changeParams={changeParams}
                        isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                    />
                    <SettingImages
                        image={dataActual?.image}
                        changeParams={changeParams}
                        name={parentComponent}
                        isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                        type={dataActual?.type}
                    />
                    <SettingLink
                        key={getKey(2)}
                        link={dataActual?.link}
                        changeParams={changeParams}
                        isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                    />
                    <SettingTheme
                        key={getKey(3)}
                        blockTheme={dataActual?.blockTheme}
                        changeParams={changeParams}
                        isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                    />
                    <SettingStories
                        active={dataActual?.isStories}
                        storiesData={dataActual?.stories}
                        data={dataActual}
                        changeParams={changeParams}
                    />
                    <SettingStylesMain
                        key={getKey(4)}
                        styles={dataActual?.styles}
                        changeParams={changeParams}
                        isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                    />
                    <SettingVideo
                        dash_src={(dataActual?.dash_src !== undefined && device === 'phone') ? dataActual?.dash_src : undefined}
                        video={dataActual?.video}
                        isPlay={dataActual?.isPlay}
                        type={dataActual?.type}
                        name={dataActual?.name}
                        changeParams={changeParams}
                        isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                    />
                    <SettingView
                        key={getKey(5)}
                        view={dataActual?.view}
                        changeParams={changeParams}
                        isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                    />
                    <SettingCategorySlider
                        key={getKey(6)}
                        category={dataActual?.category}
                        lang={dataActual?.lang}
                        shop={dataActual?.shop}
                        count={dataActual?.count}
                        changeParams={changeParams}
                        isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                    />
                    <SettingDatePiker
                        key={getKey(7)}
                        datePiker={dataActual?.datePiker}
                        changeParams={changeParams}
                        isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                    />
                    <SettingTypeHalfSlider
                        sliderPosition={dataActual?.sliderPosition}
                        changeParams={changeParams}
                        isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                    />
                    <SettingSlidePerView
                        slidePerView={dataActual?.slidePerView}
                        changeParams={changeParams}
                        isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                    />
                    <SettingSlidePerGroup
                        slidePerGroup={dataActual?.slidePerGroup}
                        changeParams={changeParams}
                        isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                    />
                </>
                : activeMenu === 'components' ?
                    <>
                        <AddComponent
                            name={dataActual?.name}
                            isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                        />
                        <AddReplacement
                            key={getKey(8)}
                            name={data?.name}
                            dataReplacement={dataReplacement}
                            data={data}
                            changeParams={changeParams}
                        />
                    </>
                    : activeMenu === 'other' ?
                        <>
                            <SettingText
                                key={getKey(9)}
                                text={dataActual?.text}
                                changeParams={changeParams}
                                isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                            />
                            <SettingTextSliced
                                key={getKey(lang)}
                                textSliced={dataActual?.textSliced}
                                changeParams={changeParams}
                                isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                            />
                            <SettingStyles
                                key={getKey(10)}
                                styles={dataActual?.styles || dataActual?.childrenStyles}
                                changeParams={changeParams}
                                isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                                parentStylesTextSliced={parentStylesTextSliced}
                            />
                            <SettingSliderDelay
                                key={getKey(11)}
                                autoplay={dataActual?.autoplay}
                                changeParams={changeParams}
                                isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                            />
                            <SettingProgressBar
                                key={getKey(12)}
                                isProgressBar={dataActual?.isProgressBar}
                                changeParams={changeParams}
                                isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                            />
                            <SettingAutoplay
                                key={getKey(13)}
                                changeParams={changeParams}
                                name={parentComponent}
                                isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                            />
                            <SettingPaginationSwiper
                                key={getKey(14)}
                                isPagination={dataActual?.isPagination}
                                isDarkPagination={dataActual?.isDarkPagination}
                                changeParams={changeParams}
                                isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                            />
                            <SettingAutoplaySwiper
                                key={getKey(15)}
                                isAutoplay={dataActual?.isAutoplay}
                                changeParams={changeParams}
                                isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                            />
                            {
                                dataActual?.name === "block-slider-category-v2" && (
                                    <>
                                        <Divider/>
                                        <div style={{marginTop: "20px", marginBottom: "40px"}}>
                                            <h3 style={{fontSize: "16px", opacity: .8}}>Стили для название</h3>
                                            <SettingStyles
                                                key={getKey(16)}
                                                styles={dataActual?.nameStyles}
                                                updateCallback={(value) => changeParams("nameStyles", value)}
                                                isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                                                parentStylesTextSliced={parentStylesTextSliced}
                                            />
                                        </div>

                                        <h3 style={{fontSize: "16px", opacity: .8}}>Стили для цены</h3>
                                        <SettingStyles
                                            key={getKey(16)}
                                            styles={dataActual?.priceStyles}
                                            updateCallback={(value) => changeParams("priceStyles", value)}
                                            isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                                            parentStylesTextSliced={parentStylesTextSliced}
                                        />
                                    </>
                                )
                            }
                            <SettingAnimation
                                hover={dataActual?.hover}
                                animate={dataActual?.animate}
                                changeParams={changeParams}
                                isReplacement={dataReplacement?.deadline || dataReplacement?.isPreview}
                            />
                        </>
                        :
                        <></>
            }
            {!defaultKey ?
                <div className={styled.emptyStyle}>
                    Не выбран ни один компонент
                </div>
                :
                <></>
            }
        </div>
    );
};

export default SideStyles;
