import React, { forwardRef } from 'react';
import { useSelector } from "react-redux";
import BorderActive from "../../workFields/BorderActive";
import { getLang, getTheme } from "../../../../redux/selectors/editorSelectors";
import { useBorderActive } from "../../../../hooks/useBorderActive";
import { useNavigate } from "react-router";
import cls from '../../../../styles/editorPage/workField.module.scss'
import { useDynamicGradientBg } from "./useDynamicGradientBg";
import styled from 'styled-components'

const Button = styled.button`
    font-weight: ${props => props.$fontWeight};
    font-size: ${props => props.$fontSize};
    font-family: ${props => props.$fontFamily};
    border: 1px solid transparent;
    border-image: ${props => props.$borderImage};
    color: ${props => props.$color};
    border-image-slice: 1;
    background: transparent;
/*    filter: invert(100%);*/
    background-size: 100%;
`

const ButtonOutlineSticky = forwardRef((props, refImg) => {

  const { mainId, blockId, component, isActivePage, collection } = props;

  const theme = useSelector(getTheme)
  const lang = useSelector(state => getLang(state));

  let navigate = useNavigate();

  const {
    width, height, isHide, ref, resize,
    idActive, selectThisComponent,
  } = useBorderActive(component, [mainId, blockId, component.id])

  const gradient = useDynamicGradientBg(refImg, ref, theme === "light" ? component.styles.color : component.styles.colorDark, theme === "light" ? component.styles.invertedColor : component.styles.invertedColorDark)
  const gradientBorder = useDynamicGradientBg(refImg, ref, theme === "light" ? component.styles.color : component.styles.colorDark, theme === "light" ? component.styles.invertedColor : component.styles.invertedColorDark)

  const setActiveOrLinkTo = (event) => {
    if (!isHide) {
      resize()
      selectThisComponent(event)
    } else {
      if (component?.link?.isActive) {
        if (component?.link?.out) {
          window.open(component.link.path)
        } else {
          let arr = component.link.path.split('.com')
          navigate(arr[1].slice(7))
        }
      }
    }
  }

  return (
    <div
      className={cls.frontSpaceWrapper}
      style={{
        alignSelf: component?.styles.alignSelf,
      }}
    >
      <div
        className='p-relative'
        style={{
          margin: component.styles.margin.join(' ')
        }}>
        {!isHide && idActive.length === 3 && component.id === idActive[2] && !isActivePage &&
          <BorderActive
            width={width}
            height={height}
            id={[mainId, blockId, component.id]}
            large={true}
            collection={collection}
            container={true}
          />}
        <Button
          ref={ref}
          className={`${cls.buttonOutline}`}
          $fontWeight={component.styles.fontWeight}
          $fontStyle={component.styles.fontStyle || 'normal'}
          $fontSize={component.styles.fontSize}
          $fontFamily={component.styles.fontFamily}
          $borderImage={gradientBorder}
          $color={theme === "light" ? component.styles.color : component.styles.colorDark}
          onClick={setActiveOrLinkTo}
        >
          <span
            className={`${cls.invert} ${cls.innerTextBtnSticky}`}
            style={{ backgroundImage: gradient }}
          >
            {component.text[lang]}
          </span>
        </Button>
      </div>
    </div>
  );
});

export default ButtonOutlineSticky;