import React from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import {Input} from "antd";

const SettingSlidePerView = ({slidePerView, changeParams, isReplacement}) => {

    return (
        <React.Fragment>
            {slidePerView !== undefined
                ?
                <div>
                    <div className={styled.textAndInput}>
                        <div className={styled.label}>
                            Отображение:
                        </div>
                        <Input
                            style={{width: '75px'}}
                            size={'small'}
                            defaultValue={slidePerView}
                            onChange={(e) => changeParams('slidePerView', e.target.value, isReplacement)}
                        />
                    </div>
                </div>
                :
                <></>
            }
        </React.Fragment>
    );
};


export default SettingSlidePerView;
