import React, {useMemo, useState} from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import {useSelector} from "react-redux";
import {getDevice} from "../../../../redux/selectors/editorSelectors";
import ModalFileManager from "../../../Modals/ModalFileManager";
import {Checkbox} from "antd";

const SettingImages = ({type, image, changeParams, name, isReplacement}) => {


    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const device = useSelector(state => getDevice(state));

    const inform = useMemo(() => {
        if (image?.ratioDesk) {
            return `Рекомендуемые параметры изображения, для соблюдения пропорций компонента - ${
                device === 'desktop' ? image.ratioDesk : image.ratioPhone
            }`
        }
        if (name === 'block-image' || name === 'block-slider') {
            return 'Рекомендуемые параметры изображения, для соблюдения пропорций компонента - 3024x3780'
        }
        if (name === 'block-video' || name === 'block-slider-full' || name === 'block-image-full-screen') {
            if (device === 'desktop') {
                return 'Рекомендуемые параметры изображения, для соблюдения пропорций компонента - 1920х1080'
            } else {
                return 'Рекомендуемые параметры изображения, для соблюдения пропорций компонента - 1080х1920'
            }
        }
        if (name === 'block-image-full-w-half-h' || name === 'block-slider-full') {
            return 'Рекомендуемые параметры изображения, для соблюдения пропорций компонента - 1920х540'
        }
        return 'Рекомендуемые параметры изображения, для соблюдения пропорций компонента - 1080х1920'
    }, [name, image])

    const title = useMemo(() => {
        if (name === 'block-video' || type === 'video') {
            return 'Постер';
        }
        return 'Изображение';
    }, [name, type])

    const [isLuminensed, setIsLuminensed] = useState(false)
    const setNewImage = (val) => {
        if (isLuminensed && name === "block-image-full-screen" && val.info.luminance) {
            val.info.luminance = 150
        }
        changeParams('image', val, isReplacement)
        handleClose()

    }

    return (
        <div>
            {name === "block-image-full-screen" &&
                <div>
                    <div className={styled.checkboxContainer}>
                        <div className={styled.checkbox} style={{marginLeft: "15px", marginBottom: "10px"}}>
                            <Checkbox
                                checked={isLuminensed}
                                onChange={(e) => setIsLuminensed(e.target.checked)}
                            >
                                Базовая шапка
                            </Checkbox>
                        </div>
                    </div>
                </div>

            }
            {image
                ?
                <div>
                    <h3>{title}</h3>
                    <div className={`${styled.addImage}`}>
                        <div
                            className={styled.icons}
                            onClick={handleOpen}
                            style={{
                                backgroundImage: `url(${image.url})`,
                            }}
                        >
                            <div className={styled.plus}>+</div>
                            <div className={styled.upload}>
                                Upload
                            </div>
                        </div>
                        <div className={styled.inform}>
                            {inform}
                        </div>
                    </div>
                </div>
                :
                <></>
            }
            <ModalFileManager open={open} handleClose={handleClose} setNewImage={setNewImage}/>
        </div>
    );
};

export default SettingImages;
