import {ADD_HIERARCHY, ADD_FILES} from '../types'

let initialState = {
  hierarchy: [],
  files: [],
};

const imagesReducer = (state = initialState, action) => {

  switch (action.type) {
    case ADD_HIERARCHY:
      return {
        ...state, hierarchy: action.hierarchy
      }
    case ADD_FILES:
      return {
        ...state, files: action.files
      }
    default:
      return state;
  }
}

export const setHierarchy = (hierarchy) => ({type: ADD_HIERARCHY, hierarchy});
export const setFiles = (files) => ({type: ADD_FILES, files});

export default imagesReducer;
