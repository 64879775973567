import React, { forwardRef, useMemo, useState } from 'react';

const ImageAnimate = forwardRef((props, refImg) => {

  const { src, isHovered, isAppearance, hoverImage, appearanceImage, appearanceImageIncrease } = props;

  const containerStyle = {
    width: `100%`,
    height: `100%`,
    position: 'relative',
    overflow: 'hidden',
  };

  const imgStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const imgStyleHover = {
    transition: 'transform 0.8s ease',
    transform: isHovered ? 'scale(1.15)' : 'scale(1)',
  };

  const imgStyleAppearance = {
    transition: 'transform 2s cubic-bezier(0.5, 0, 0, 1)',
    transform: isAppearance ? 'scale(1)' : 'scale(1.25)',
  };
  const imgStyleAppearanceIncrease = {
    transition: 'transform 2s cubic-bezier(0.5, 0, 0, 1)',
    transform: isAppearance ? 'scale(1.25)' : 'scale(1)',
  };

  const imgSt = useMemo(() => {
    if (hoverImage) {
      return {...imgStyle, ...imgStyleHover}
    }
    if (appearanceImage) {
      return {...imgStyle, ...imgStyleAppearance}
    }
    if (appearanceImageIncrease) {
      return {...imgStyle, ...imgStyleAppearanceIncrease}
    }
    return imgStyle
  }, [hoverImage, isHovered, isAppearance, appearanceImage, appearanceImageIncrease])

  return (
    <div
      style={containerStyle}
      ref={refImg}
    >
      <img
        src={src}
        alt="image"
        style={imgSt}
      />
    </div>
  );
});

export default ImageAnimate;