import React from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import { Checkbox, Input } from "antd";
import {useDispatch} from "react-redux";
import {setAutoplay} from "../../../../redux/reducers/editorReducer";

const SettingAutoplay = ({name, changeParams, isReplacement}) => {

  const dispatch = useDispatch()
  const changeAutoplay = (val) => {
    localStorage.setItem('autoplay', val)
    dispatch(setAutoplay())
  }
  const autoplay = localStorage.getItem('autoplay')

  return (
    <React.Fragment>
      {name === "block-slider-full" || name === "block-slider-image"
        ?
          <div
            className={styled.rightStylesMainMargin}
            key={name}
            style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}
          >
            <div style={{ fontWeight: 500 }}>
              Автопроигрывание в редакторе
            </div>
            <div className={styled.checkbox}>
              <Checkbox
                defaultChecked={!!autoplay}
                onChange={(e) => changeAutoplay(e.target.checked)}
              >
                Выключить
              </Checkbox>
            </div>
          </div>
        :
        <></>
      }
    </React.Fragment>
  );
};


export default SettingAutoplay;
