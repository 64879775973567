import React, {useState} from 'react';
import {Space, Table, Tag, Tooltip} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import Modal from "antd/es/modal";
import {editorAPI} from "../../api/api";
import {setTemplates} from "../../redux/actions/templatesAction";
import {useDispatch, useSelector} from "react-redux";
import ModalCountries from "../Modals/ModalCountries";
import {useNavigate} from "react-router";
import {getActivePage, getPaginationData} from "../../redux/selectors/templatesSelectors";
import {setActivePage} from "../../redux/reducers/templatesReducer";

const TemplatesList = ({templates, setMessages}) => {

	const [openModalShops, setOpenModalShops] = useState(false);
	const [id, setId] = useState(null)
	const [active, setActive] = useState([])
	const [nameCurrent, setNameCurrent] = useState('')
	const activePage = useSelector(state => getActivePage(state));
	const paginationData = useSelector(state => getPaginationData(state));

	const handleCloseShops = () => {
		setOpenModalShops(false)
	}

	const handleOpen = async (id, active, name) => {
		const res = await editorAPI.getById(id)
		if (res.api_success) {
			let desktopSchema = [{components: res.page.schema.desktop}]
			let mobileSchema = [{components: res.page.schema.mobile}]
			let isDefault = false
			let noChangedValue = ''
			const checkDefaultValues = (arr) => {
				for (let item of arr) {
					if (item.image && item.image.url.indexOf('/static/media') !== -1) {
						if (item.image.url.indexOf('/static/media/certificates_new') === -1) {
							isDefault = true
							noChangedValue = 'Измените изображение компонента!'
							return;
						}
					}
					/*if (item.text) {
						let values = Object.values(item.text)
						if (values.includes('КАКОЙ-ТО ТЕКСТ')
					  	|| values.includes('SOME TEXT')
							|| values.includes('ETWAS TEXT')
							|| values.includes('بعض النصوص')
						) {
							isDefault = true
							noChangedValue = 'Измените текст компонента!'
							return;
						}
					}*/
					if (item.components && item.components.length) {
						checkDefaultValues(item.components)
					}
				}
			}
			checkDefaultValues(desktopSchema)
			if (!isDefault) {
				checkDefaultValues(mobileSchema)
			}
			if (!isDefault) {
				setId(id)
				setActive(active)
				setNameCurrent(name)
				setOpenModalShops(true)
			} else {
				showModalInform(name, noChangedValue)
			}
		}
	}

	const dispatch = useDispatch()


	const {confirm, info} = Modal;

	const showModalInform = (name, noChangedValue) => {
		info({
			title: 'Предупреждение',
			content: `Вы не можете сделать активным страницу "${name}", так как не все изначальные значения были изменены. ${noChangedValue}`,
			onOk() {},
		});
	}

	const showModalDelete = (id) => {
		confirm({
			title: 'Подтвердить',
			icon: <ExclamationCircleOutlined/>,
			content: 'Вы уверены, что хотите удалить эту страницу?',
			okType: 'danger',
			onOk() {
				return editorAPI.deletePage(id).then(result => {
					setMessages(result.api_messages)
					if (result.api_success) {
						return dispatch(setTemplates(activePage))
					}
					return;
				})
					.catch(errors => console.log(errors));
			},
		});
	}

	const showModalCopy = (id, name) => {
		confirm({
			title: 'Копировать "' + name + '"',
			icon: <ExclamationCircleOutlined/>,
			content: 'Вы уверены, что хотите сделать копию этой страницы?',
			okType: 'primary',
			onOk() {
				return editorAPI.copyPage(id).then(result => {
					setMessages(result.api_messages)
					if (result.api_success) {
						return dispatch(setTemplates(activePage))
					}
					return;
				})
					.catch(errors => console.log(errors));
			},
		});
	}

	let navigate = useNavigate();

	const goTo = (path) => {
		navigate('/' + path)
	}

	const columns = [
		{
			title: '#',
			render: (_, record) => (
				<span>{record?.hp_id}</span>
			),
			key: 'id',
		},
		{
			title: 'Наименование',
			dataIndex: 'hp_name',
			key: 'name',
		},
		{
			title: 'Активен',
			key: 'countries',
			render: (_, record) => (
				<>
					{record.hp_active.length ?
						<>
							{record.hp_active.map((item, key) =>
								<Tooltip key={key} placement="topLeft" title={item.name}>
									<Tag color="blue" key={key}>{item.id}</Tag>
								</Tooltip>
							)}
						</>
						:
						<></>
					}
				</>
			),
		},
		{
			title: 'Дата создания',
			key: 'date',
			dataIndex: 'hp_created',
		},
		{
			title: 'Автор',
			key: 'date',
			render: (_, record) => (
				<span>{record.hp_user?.name}</span>
			),
		},
		{
			title: 'Действия',
			key: 'action',
			render: (_, record) => (
				<Space size='large'>
					<span
						className="f-icon icon-pencil hover-blue fs-20"
						onClick={() => goTo(record.hp_id)}
					/>
					<span
						className="f-icon icon-copy hover-blue fs-20"
						onClick={() => showModalCopy(record.hp_id, record.hp_name)}
					/>
					{record.hp_active.length ?
						<>
							<span className="icon-trash white-gray fs-20"/>
							<div
								onClick={() => handleOpen(record.hp_id, record.hp_active, record.hp_name)}
								className={'blue'} style={{cursor: 'pointer'}}
							>
								<span
									className="f-icon icon-check fs-20"
								/> Активен
							</div>
						</>
						:
						<>
							<span
								className="f-icon icon-trash red fs-20"
								onClick={() => showModalDelete(record.hp_id)}
							/>
							<div
								onClick={() => handleOpen(record.hp_id, record.hp_active, record.hp_name)}
								className={'hover-blue'}
							>
								<span
									className="f-icon icon-check fs-20"
								/> Активировать
							</div>
						</>
					}
				</Space>
			),
		},
	];
	const setPage = (page) => {
		console.log(page.current)
		dispatch(setActivePage(page.current))
	}
	return (
		<>
			<Table
				columns={columns}
				dataSource={templates}
				pagination={{total: paginationData?.total, pageSize: paginationData?.limit}}
				onChange={setPage}
			/>
			<ModalCountries
				open={openModalShops}
				handleClose={handleCloseShops}
				id={id}
				setMessages={setMessages}
				active={active}
				name={nameCurrent}
			/>
		</>
	);
};

export default TemplatesList;
