import React, {useEffect, useState} from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import blockImage from '../../../../assets/templates/block-image.png'
import blockImageFullScreen from '../../../../assets/templates/block-image-full-screen.png'
import blockImageWHalfH from '../../../../assets/templates/block-image-full-w-half-h.png'
import blockSlider from '../../../../assets/templates/block-slider.png'
import blockSliderImage from '../../../../assets/templates/block-slider-image.png'
import blockSliderFull from '../../../../assets/templates/block-slider-full.png'
import blockVideo from '../../../../assets/templates/block-video.png'
import blockCustomSize from '../../../../assets/templates/block-custom-size.png'
import {useDispatch, useSelector} from "react-redux";
import {setComponentCollection} from "../../../../redux/reducers/editorReducer";
import {getInitialStyles, getInitialStylesSW} from "../../../../helpers/initialStylesForComponents";
import ModalCountBloks from "../../../Modals/ModalCountBloks";
import {message} from "antd";
import ModalCategoryBloks from "../../../Modals/ModalCategoryBlock";
import {
    getComponentCollection,
    getComponentCollectionMobile,
    getDevice, getLangList
} from "../../../../redux/selectors/editorSelectors";
import Modal from "antd/es/modal";
import ModalCustomSize from "../../../Modals/ModalCustomSize";
import ModalCountHalfSlider from "../../../Modals/ModalCountHalfSlider";

const Templates = () => {
    const {info} = Modal;

    const [openModal, setOpenModal] = useState(false);
    const [openModalCategory, setOpenModalCategory] = useState(false);
    const [openModalSize, setOpenModalSize] = useState(false);
    const [openModalHalf, setOpenModalHalf] = useState(false);
    const [name, setName] = useState(false);
    const [type, setType] = useState(false);
    const [messages, setMessages] = useState([])

    const dispatch = useDispatch()

    const device = useSelector(state => getDevice(state));
    const langList = useSelector(state => getLangList(state));
    const componentCollection = useSelector(state => getComponentCollection(state));
    const componentCollectionMobile = useSelector(state => getComponentCollectionMobile(state));

    const handleClose = () => setOpenModal(false);
    const handleCloseHalf = () => setOpenModalHalf(false);
    const handleCloseSize = () => setOpenModalSize(false);
    const handleCloseCategory = () => setOpenModalCategory(false);
    const handleOpen = () => setOpenModal(true);
    const handleOpenHalf = () => setOpenModalHalf(true);
    const handleOpenSize = () => setOpenModalSize(true);
    const handleOpenCategory = () => setOpenModalCategory(true);

    const selectCollection = () => {
        if (device === 'desktop') {
            return componentCollection
        }
        if (device === 'tablet' || device === 'phone') {
            return componentCollectionMobile
        }
    }

    const showModalInform = () => {
        info({
            title: 'Предупреждение',
            content: `Вы не можете добавить более одного компонента данного типа на станицу!`,
            onOk() {
            },
        });
    }

    const addComponent = (name, count) => {
        let component = {}
        if (name === 'block-video') {
            component = getInitialStylesSW(name, count, langList)
        } else {
            component = getInitialStyles(name, count, langList)
        }
        dispatch(setComponentCollection(component))
    }

    const addComponentModal = (name, type) => {
        setName(name)
        setType(type)

        if (name === "block-slider-category-v2") {
            handleOpen()
        } else {
            handleOpen()
        }
    }
    const addComponentHalfSlider = () => handleOpenHalf()
    const addComponentModalSize = (name) => {
        setName(name)
        handleOpenSize()
    }
    const addComponentModalCategory = (name, type) => {
        let check = false;

        for (let item of selectCollection()) {
            if (item.name === 'block-slider-category') {
                check = true
            }
        }
        if (check) {
            showModalInform()
        } else {
            setName(name)
            setType(type)
            handleOpenCategory()
        }
    }

    const handleCloseError = () => setMessages([])

    const showMessage = () => {
        message.info({
            content: messages[0],
            onClose: handleCloseError
        });
    };

    useEffect(() => {
        if (messages.length) {
            showMessage()
        }
    }, [messages])

    return (
        <div className={styled.templatesContainer}>
            <div className={styled.templates}>
                <div
                    className={styled.template}
                    onDoubleClick={() => addComponentModal('block-image', 'grid')}
                >
                    <img src={blockImage} alt=""/>
                    <div className={styled.name}>Block Image</div>
                </div>
                <div
                    className={styled.template}
                    onDoubleClick={() => addComponentModalSize('block-custom-size')}
                >
                    <img src={blockCustomSize} alt="" style={{height: '40px', width: '58px', margin: '3px auto 18px'}}/>
                    <div className={styled.name}>Block Custom Size</div>
                </div>
                <div
                    className={styled.template}
                    onDoubleClick={() => addComponent('block-image-full-screen', 1)}
                >
                    <img src={blockImageFullScreen} alt=""/>
                    <div className={styled.name}>Block Image Full Screen</div>
                </div>
                <div
                    className={styled.template}
                    onDoubleClick={() => addComponent('block-image-w-half-h', 1)}
                >
                    <img src={blockImageWHalfH} alt=""/>
                    <div className={styled.name}>Block Image Half</div>
                </div>
                <div
                    className={styled.template}
                    onDoubleClick={() => addComponent('block-video', 2)}
                >
                    <img src={blockVideo} alt=""/>
                    <div className={styled.name}>Block Video</div>
                </div>
                <div
                    className={styled.template}
                    onDoubleClick={() => addComponentModal('block-slider-full', 'swiper')}
                >
                    <img src={blockSliderFull} alt=""/>
                    <div className={styled.name}>Block Slider Full</div>
                </div>
                <div
                    className={styled.template}
                    onDoubleClick={() => addComponentHalfSlider()}
                >
                    <img src={blockSliderImage} alt=""/>
                    <div className={styled.name}>Block Slider Image</div>
                </div>
                <div
                    className={styled.template}
                    onDoubleClick={() => addComponentModal('block-slider', 'swiper')}
                >
                    <img src={blockSlider} alt=""/>
                    <div className={styled.name}>Block Slider</div>
                </div>
                <div
                    className={styled.template}
                    onDoubleClick={() => addComponentModalCategory('block-slider-category', 'slider-category')}
                >
                    <img src={blockSlider} alt=""/>
                    <div className={styled.name}>Block Slider Category</div>
                </div>
                <div
                    className={styled.template}
                    onDoubleClick={() => addComponentModal('stories-single', 'grid')}
                >
                    <img src={blockImage} alt=""/>
                    <div className={styled.name}>Single Stories</div>
                </div>
                <div
                    className={styled.template}
                    onDoubleClick={() => addComponentModal('stories-multi', 'swiper')}
                >
                    <img src={blockSlider} alt=""/>
                    <div className={styled.name}>Multi Stories</div>
                </div>
                <div
                    className={styled.template}
                    onDoubleClick={() => addComponentModal('block-slider-category-v2', 'swiper')}
                >
                    <img src={blockSlider} alt=""/>
                    <div className={styled.name}>Block New Category V2</div>
                </div>
                <div
                    className={styled.template}
                    onDoubleClick={() => addComponentModal('block-image-saint', 'grid')}
                >
                    <img src={blockImage} alt=""/>
                    <div className={styled.name}>Saint Laurent Block</div>
                </div>
                <div
                    className={styled.template}
                    onDoubleClick={() => addComponentModal('saint-laurent-slider-full', 'swiper')}
                >
                    <img src={blockSlider} alt=""/>
                    <div className={styled.name}>Saint Laurent Slider</div>
                </div>

                <ModalCountBloks
                    name={name}
                    type={type}
                    open={openModal}
                    langList={langList}
                    handleClose={handleClose}
                />
                <ModalCountHalfSlider
                    langList={langList}
                    open={openModalHalf}
                    handleClose={handleCloseHalf}
                />
                <ModalCategoryBloks
                    name={name}
                    type={type}
                    langList={langList}
                    open={openModalCategory}
                    handleClose={handleCloseCategory}
                />
                <ModalCustomSize
                    name={name}
                    open={openModalSize}
                    handleClose={handleCloseSize}
                />
            </div>
        </div>
    );
};

export default Templates;
