import React, {useState} from 'react';
import {Button, Modal} from "antd";
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import StoriesSlider from "../../components/stories/sliders/StoriesSlider";

/**
 * @author Zholaman Zhumanov
 * @created 11.07.2024
 * @description Компонент для вывода кнопки для сториса
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function SettingStories(props) {
    const {active, storiesData, changeParams} = props

    const [modal, setModal] = useState(false)

    const toggleModal = () => setModal(!modal)

    if (!active) return null
    if (!modal) {
        return (
            <Button
                onClick={toggleModal}
                type="primary"
                style={{margin: "15px"}}
            >
                <span>Добавить сторисы</span>
            </Button>
        )
    }

    return (
        <>
            <Button
                onClick={toggleModal}
                type="primary"
                style={{margin: "15px"}}
            >
                <span>Добавить сторисы</span>
            </Button>

            <Modal className={styled.stories_modal} open={modal} title="Сторисы" footer={null} onCancel={toggleModal}>
                {
                    modal ?  (
                        <StoriesSlider
                            active={modal}
                            stateData={storiesData}
                            toggleModal={toggleModal}
                            changeParams={changeParams}
                        />
                    ) : null
                }
            </Modal>
        </>
    );
}

export default React.memo(SettingStories);
