import React, {useEffect, useState} from 'react';
import styled from '../../../../styles/editorPage/workField.module.scss'
import BorderActive from "../../workFields/BorderActive";
import DashPlayer from "../../workFields/DashPlayer";
import {useInView} from "react-intersection-observer";
import {useBorderActive} from "../../../../hooks/useBorderActive";
import {useNavigate} from "react-router";
import DashPlayerBlock from "../../workFields/DashPlayerBlock";

const BlockVideo = ({heightComponent, firstLoad, load, mainId, device, component, parentName, collection, isActivePage}) => {

	const [refInView, inView] = useInView({threshold: 0.1})

	let navigate = useNavigate();

	const {
		width, height, isHide, resize,
		idActive, selectThisComponent, ref
	} = useBorderActive(component, [mainId, component.id], load)


	const setActiveOrLinkTo = (event) => {
		if (!isHide) {
			resize()
			selectThisComponent(event)
		} else {
			if (component?.link?.isActive) {
				if (component?.link?.out) {
					window.open(component.link.path)
				} else {
					let arr = component.link.path.split('.com')
					navigate(arr[1].slice(7))
				}
			}
		}
	}

	return (

		<div className='p-relative w-100'>
			{!isHide && idActive.length === 2 && idActive[1] === component?.id && !isActivePage &&
				<BorderActive
					width={width}
					height={height}
					btnDown={true}
					id={[mainId, component.id]}
				/>
			}
			<div
				ref={refInView}
				className={`${inView ? styled.mosaicCardShow : styled.mosaicCardHide}`}
			>
				<div
					onContextMenu={(e) => e.preventDefault()}
					ref={ref}
					onClick={setActiveOrLinkTo}
					style={{
						marginBottom: component?.styles?.marginBottom,
						height: heightComponent + 'px'
					}}
				>
					<DashPlayerBlock
						src={component.video}
						poster={component?.image?.url}
						autoplay={component?.isPlay}
						component={component}
						mainId={mainId}
						isActivePage={isActivePage}
						heightComponent={heightComponent}
						firstLoad={firstLoad}
					/>
				</div>
			</div>
		</div>
	);
};

export default BlockVideo;