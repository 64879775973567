import React, { forwardRef, useMemo } from 'react';
import styled from '../../../../styles/editorPage/workField.module.scss'
import {useSelector} from "react-redux";
import BorderActive from "../../workFields/BorderActive";
import { getLang, getTheme } from "../../../../redux/selectors/editorSelectors";
import {useBorderActive} from "../../../../hooks/useBorderActive";

const Text = (props) => {

	const {mainId, blockId, component, mouseOver, hover, isActivePage, collection} = props

	const lang = useSelector(state => getLang(state));
	const theme = useSelector(getTheme)

	const {
		width, height, isHide, ref, resize,
		idActive, selectThisComponent,
	} = useBorderActive(component, [mainId, blockId, component.id])

	const select = (e) => {
		resize()
		selectThisComponent(e)
	}

	const classHover = useMemo(() => {
		if (!hover) {
			return ''
		}
		if (mouseOver) {
			return `${styled.textHover} ${styled.textHoverActive}`
		} else {
			return styled.textHover
		}
		return ''
	}, [hover, mouseOver])

	return (
		<div
			className={styled.frontSpaceWrapper}
			style={{
				alignSelf: component?.styles.alignSelf,
			}}
		>
			<div
				className='p-relative'
				style={{
				margin: component.styles.margin.join(' '),
			}}>
				{!isHide && idActive.length === 3 && component.id === idActive[2] && !isActivePage &&
				<BorderActive
					width={width}
					height={height}
					id={[mainId, blockId, component.id]}
					large={true}
					collection={collection}
					container={true}
				/>
				}
				<div
					className={classHover}
					ref={ref}
					style={{
						fontStyle: component.styles.fontStyle || 'normal',
						fontWeight: component.styles.fontWeight,
						fontSize: component.styles.fontSize,
						fontFamily: component.styles.fontFamily,
						color: theme === "light" ? component.styles.color : component.styles.colorDark,
						textAlign: component.styles?.textAlign,
					}}
					onClick={select}
				>
					{component.text[lang]}
				</div>
			</div>
		</div>
	);
};

export default Text;