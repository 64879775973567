import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setComponentCollection} from "../../redux/reducers/editorReducer";
import {Form, InputNumber, Modal, Select} from 'antd';
import {editorAPI} from "../../api/api";
import uuid from "react-uuid";


export default function ModalCategoryBloks({open, handleClose, name, type, langList}) {

	const [count, setCount] = useState(10);
	const [currentShop, setCurrentShop] = useState('kz');
	const [lang, setLang] = useState(1);
	const [shop, setShop] = useState(1);
	const [category, setCategory] = useState('new');
	const [params, setParams] = useState({});
	const [shops, setShops] = useState([]);
	const [categories, setCategories] = useState([]);


	const getParams = async () => {
		let myHeaders = new Headers();
		myHeaders.append("User-Agent", "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36");

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const res = await fetch(`https://api.lichi.com/site/info`, requestOptions)
			.then(response => response.json())
			.then(result => result.api_data.data.info.shop)
			.catch(error => console.log('error', error));
		setParams(res)
	}
	const getShops = async () => {
		const res = await editorAPI.getShops()
		if (res.api_success) {
			setShops(res.list)
		}
	}
	const getCategories = async () => {
		let myHeaders = new Headers();
		myHeaders.append("User-Agent", "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36");

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const res = await fetch(`https://api.lichi.com//category/get_category_list?lang=${lang}&shop=${shop}`, requestOptions)
			.then(response => response.json())
			.then(result => result)
			.catch(error => console.log('error', error));
		setCategories(res.api_data.aData)
	}

	useEffect( () => {
		getParams()
		getShops()
	}, [])
	useEffect( () => {
		if (shop) {
			getCategories()
		}
	}, [shop])
	useEffect( () => {
		setShop(params[currentShop]?.id)
		setLang(params[currentShop]?.language_def_id)
	}, [currentShop, params])

	const dispatch = useDispatch();

/*	const chageCount = (e, val) => {
		if (e?.keyCode == 13) {
			e.preventDefault()
			createNewComponent()
		}
		setCount(val)
	}*/


	const createNewComponent = () => {
		let component = {
			name: name,
			id: uuid(),
			count: count,
			lang: lang,
			shop: shop,
			category: category,
			view: {
				wholesale: true,
				countries: {
					checked: ['all'],
					all: true
				}
			},
			styles: {
				marginBottom: '2px',
				spaceBetween: 2,
			},
			animate: {
				showFlash: true
			},
		}
		dispatch(setComponentCollection(component))
		handleClose()
	}



	const layout = {
		labelCol: {
			span: 14,
		},
		wrapperCol: {
			span: 10,
		},
	};


	return (
		<div>
			<Modal
				visible={open}
				onOk={createNewComponent}
				onCancel={handleClose}
				title={name}
				okText="Добавить"
				cancelText="Отмена"
				closeIcon={<span className="f-icon icon-cross fs-17"/>}
			>
				<Form {...layout} style={{ margin: '0 70px 0 0'}}>
					<Form.Item
						style={{marginBottom: '15px'}}
						name={'country'}
						label="Укажите страну"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Select
							style={{width: '100%'}}
							defaultValue={currentShop}
							onChange={(e, item) => setCurrentShop(item.value)}
						>
							{shops.map((item, key) =>
								<Select.Option key={key} value={item.id}>{item.name}</Select.Option>
							)}
						</Select>
					</Form.Item>
					<Form.Item
						style={{marginBottom: '15px'}}
						name={'category'}
						label="Укажите категорию"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Select
							style={{width: '100%'}}
							defaultValue={category}
							onChange={(e, item) => setCategory(item.value)}
						>
							{categories?.length && categories.map((item, key) =>
								<Select.Option key={key} value={item.url}>{item.name}</Select.Option>
							)}
						</Select>
					</Form.Item>
						<Form.Item
							style={{marginBottom: '15px'}}
							name={'count'}
							label="Укажите кол-во блоков"
							rules={[
								{
									required: true,
								},
							]}
						>
							<InputNumber
								style={{width: '100%'}}
								defaultValue={count}
								onChange={(val) => setCount(val)}
							/>
						</Form.Item>
				</Form>

			</Modal>

		</div>
	);
}





/*

export default function ModalCategoryBloks({open, handleClose, name, type}) {

	const [count, setCount] = useState(10);
	const [currentShop, setCurrentShop] = useState('kz');
	const [lang, setLang] = useState(1);
	const [shop, setShop] = useState(1);
	const [params, setParams] = useState({});
	const [categories, setCategories] = useState(null);
	const [shops, setShops] = useState([]);


	console.log('shops')
	console.log(shops)


	console.log('categories')
	console.log('categories')
	console.log(categories)

	const getParams = async () => {
		let myHeaders = new Headers();
		myHeaders.append("User-Agent", "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36");

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const res = await fetch(`https://api.lichi.com/site/info`, requestOptions)
			.then(response => response.json())
			.then(result => result.api_data.data.info.shop)
			.catch(error => console.log('error', error));

		setParams(res)
	}
	const getCategories = async () => {
		let myHeaders = new Headers();
		myHeaders.append("User-Agent", "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36");

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const res = await fetch(`https://api.lichi.com//category/get_category_list?lang=1&shop=2`, requestOptions)
			.then(response => response.json())
			.then(result => result)
			.catch(error => console.log('error', error));
		setCategories(res)
	}
	const getShops = async () => {
		const res = await editorAPI.getShops()
		if (res.api_success) {
			setShops(res)
		}
	}

	useEffect( () => {
		getParams()
		getShops()
	}, [])
	useEffect( () => {
		getCategories()
	}, [])

	useEffect( () => {
		setShop(params[currentShop]?.id)
		setLang(params[currentShop]?.language_def_id)
	}, [currentShop, params])

	const dispatch = useDispatch();

	/!*	const chageCount = (e, val) => {
			if (e?.keyCode == 13) {
				e.preventDefault()
				createNewComponent()
			}
			setCount(val)
		}*!/


	const createNewComponent = () => {
		let component = {
			name: name,
			id: uuid(),
			count: count,
			lang: lang,
			shop: shop
		}
		dispatch(setComponentCollection(component))
		handleClose()
	}



	const layout = {
		labelCol: {
			span: 14,
		},
		wrapperCol: {
			span: 10,
		},
	};


	return (
		<div>
			<Modal
				visible={open}
				onOk={createNewComponent}
				onCancel={handleClose}
				title={name}
				okText="Добавить"
				cancelText="Отмена"
				closeIcon={<span className="f-icon icon-cross fs-17"/>}
			>
				<Form {...layout} style={{ margin: '0 70px 0 0'}}>
					<Form.Item
						style={{marginBottom: '15px'}}
						name={'country'}
						label="Укажите страну"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Select
							style={{width: '100%'}}
							defaultValue={currentShop}
							onChange={(e, item) => setCurrentShop(item.value)}
						>
							{shops.length && shops.map((item, key) =>
								<Select.Option key={key} value={item.id}>{item.name}</Select.Option>
							)}
						</Select>
					</Form.Item>
					<Form.Item
						style={{marginBottom: '15px'}}
						name={'count'}
						label="Укажите кол-во блоков"
						rules={[
							{
								required: true,
							},
						]}
					>
						<InputNumber
							style={{width: '100%'}}
							defaultValue={count}
							onChange={(val) => setCount(val)}
						/>
					</Form.Item>
				</Form>

			</Modal>

		</div>
	);
}*/
