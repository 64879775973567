import React from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import { Checkbox, Form, Input } from "antd";

const SettingLink = ({link, changeParams, isReplacement}) => {


  const getName = (url) => {
    if (!link?.out) {
      if (url.split('.com') && url.split('.com')[1].slice(7)) {
        return url.split('.com')[1].slice(7)
      }
    }
    return url
  }

  const changeActive = (checked) => {
    if (checked) {
      changeParams('link', {isActive: checked}, isReplacement)
    } else {
      changeParams(
        'link',
        {
          isActive: checked,
          path: '',
          name: ''
        },
        isReplacement
      )
    }
  }

  return (
    <div>
      {link
        ?
        <div>
          <div className={styled.textAndInput}>
            <div className={styled.label}>
              Ссылка:
            </div>
          </div>

          <div className={`${styled.linkContainer}`}>
            <Form>
              <Form.Item
                name="age"
                rules={[{
                  message: 'Неверный формат (http://test.com)',
                  type: 'url',
                }]}
              >
                <Input
                  key={link?.isActive}
                  placeholder="link"
                  value={link.path}
                  defaultValue={link.path}
                  onChange={(e) => changeParams('link', {path: e.target.value, name: getName(e.target.value)}, isReplacement)}
                />
              </Form.Item>
            </Form>
            {/*Позже убрать проверку на наличие ключа*/}
            {link['isActive'] !== undefined
              ?
              <div className={styled.checkboxContainer}>
                <div className={styled.checkbox}>
                  <Checkbox
                    checked={link.isActive}
                    onChange={(e) => changeActive(e.target.checked)}
                  >
                    Ссылка активна
                  </Checkbox>
                </div>
                {link.isActive
                &&
                  <div className={styled.checkbox}>
                    <Checkbox
                      checked={link.out}
                      onChange={(e) => changeParams('link', {out: e.target.checked}, isReplacement)}
                    >
                      Открыть в новой вкладке
                    </Checkbox>
                  </div>
                }
              </div>
              :
              <div className={styled.checkboxLastDel}>
                <Checkbox
                  checked={link.out}
                  onChange={(e) => changeParams('link', {out: e.target.checked}, isReplacement)}
                >
                  Открыть в новой вкладке
                </Checkbox>
              </div>
            }
          </div>

        </div>
        :
        <></>
      }
    </div>
  );
};


export default SettingLink;