import React from 'react';
import styled from '../../../../../styles/editorPage/workField.module.scss'
import { useSelector } from "react-redux";
import { getTheme } from "../../../../../redux/selectors/editorSelectors";

function CountDownTimeDisplay(props) {

  const theme = useSelector(getTheme)

  const {
    value,
    type,
    isDot,
    styles
  } = props

  return (
    <div
      className={styled.display}
      style={{
        fontSize: styles.fontSizeTime,
        fontFamily: styles.fontFamily,
        fontStyle: styles.fontStyle || 'normal',
        color: theme === "light" ? styles.color : styles.colorDark
      }}
    >
      <div className={styled.display_value}>
        {value}
        {isDot && <div className={styled.display_dot}>.</div>}
      </div>
      <span
        className={styled.display_type}
        style={{
          fontSize: styles.fontSizeType,
        }}
      >
        {type}
      </span>
    </div>
  );
}

export default CountDownTimeDisplay;