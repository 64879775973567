import {imageAPI} from "../../api/api";
import {setFiles, setHierarchy} from "../reducers/imagesReducer";
import {setMessages} from "../reducers/errorsReducer";


export const requestTree = () => {
	return async (dispatch) => {
		try {
			let data = await imageAPI.getFolders();
			dispatch(setHierarchy(data.tree));
		} catch (e) {
			console.log(e.response);
		}
	}
}

export const requestFiles = (path) => {
	return async (dispatch) => {
		try {
			let data = await imageAPI.getFiles(path);
			dispatch(setFiles(data.files));
			return data
		} catch (e) {
			console.log(e.response);
		}
	}
}