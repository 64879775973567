import React from 'react';
import {Checkbox} from "antd";
import styled from "../../../../styles/editorPage/rightBarEditor.module.scss";

/**
 * @author Zholaman Zhumanov
 * @created 17.07.2024
 * @description
 * @last-updated
 * @update-description
 * @todo
 * @fixme
 * @param props
 * @constructor
 */
function SettingAutoplaySwiper(props) {
    const {isAutoplay, changeParams, isReplacement} = props

    return (
        <React.Fragment>
            {isAutoplay !== undefined ? (
                <div
                    className={styled.rightStylesMainMargin}
                    key={isAutoplay}
                    style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}
                >
                    <div style={{fontWeight: 500}}>
                        Авто-проигрывание слайдера
                    </div>
                    <div className={styled.checkbox}>
                        <Checkbox
                            checked={isAutoplay}
                            onChange={(e) => changeParams('isAutoplay', e.target.checked, isReplacement)}
                        >
                            Включить
                        </Checkbox>
                    </div>
                </div>
            ) : null
            }
        </React.Fragment>
    );
}

export default SettingAutoplaySwiper;
