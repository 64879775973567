import React from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import { Checkbox, Form, Input, Select } from "antd";
import ReactDOM from "react-dom/client";
import { Option } from "antd/es/mentions";

const SettingTypeHalfSlider = ({sliderPosition, changeParams, isReplacement}) => {

  const changePosition = (value) => {
      changeParams('sliderPosition', value, isReplacement)
  }

  return (
    <React.Fragment>
      {sliderPosition
        ?
        <div>
          <div className={styled.textAndInput}>
            <div className={styled.label}>
              Слайдер:
            </div>
            <Select
              style={{
                width: 350,
              }}
              defaultValue={sliderPosition}
              onChange={changePosition}
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Option value="right">Справа</Option>
              <Option value="left">Слева</Option>
            </Select>
          </div>
        </div>
        :
        <></>
      }
    </React.Fragment>
  );
};


export default SettingTypeHalfSlider;