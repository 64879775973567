import {CHANGE_MESSAGES} from '../types'

let initialState = {
	messages: [],
};

const errorsReducer = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_MESSAGES:
			return {
				...state, messages: action.messages
			}
		default:
			return state;
	}
}

export const setMessages = (messages) => ({type: CHANGE_MESSAGES, messages});

export default errorsReducer;
