import * as React from 'react';
import styled from '../../styles/editorPage/headerEditor.module.scss'
import {setCopyMobile, setHide} from "../../redux/reducers/editorReducer";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {Button, PageHeader, Tooltip} from 'antd';
import SettingDevice from "./RightBar/Settings/SettingDevice";
import {getComponentCollection, getComponentCollectionMobile} from "../../redux/selectors/editorSelectors";
import SettingLang from "./RightBar/Settings/SettingLang";
import {useParams} from "react-router";
import {editorAPI} from "../../api/api";
import ModalNamePage from "../Modals/ModalNamePage";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Modal from "antd/es/modal";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import {getPageTypeData} from "../../redux/selectors/templatesSelectors";


export default function HeaderEditor(props) {
    const {guid, isHide, device, setMessages, namePage, isActivePage} = props

    const {pageId} = useParams()

    const pageType = useSelector(state => getPageTypeData(state))
    const componentCollection = useSelector(state => getComponentCollection(state));
    const componentCollectionMobile = useSelector(state => getComponentCollectionMobile(state));

    const [openLangs, setOpenLangs] = useState(false);

    const dispatch = useDispatch()
    const {confirm} = Modal;

    const showPage = () => {
        dispatch(setHide(true))
    }
    const handleClose = () => setOpenLangs(false)

    const saveSchema = async () => { /*{api_success, api_messages}*/
        const {api_messages} = await editorAPI.saveSchema(pageId, componentCollection, componentCollectionMobile, {page_type: pageType})
        setMessages(api_messages)
    }

    const showModalConfirm = (id) => {
        confirm({
            title: 'Подтвердить',
            icon: <ExclamationCircleOutlined/>,
            content: 'Вы уверены, что хотите перенести все настройки данной страницы на мобильную версию?',
            onOk() {
                dispatch(setCopyMobile())
            }
        });
    }

    const goToLichiPreview = () => {
        window.open(`https://lichi.com/ru/ru?hp_id=${guid}`)
    }

    return (
        <div className={`${styled.headerEditor} ${isHide ? 'hide' : ''}`}>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={namePage}
                subTitle={"#" + pageId}
                extra={[
                    <div className={'hover-blue custom-ant'} key='1'>
                        <Button
                            key='1'
                            type={'link'}
                            onClick={goToLichiPreview}
                        >
                            <span className="f-icon icon-exit-up fs-17 mr-10"/> Предпросмотр
                        </Button>
                    </div>,
                    <div className={'hover-blue custom-ant'} key='2'>
                        <Button
                            key='2'
                            type="dashed"
                            onClick={showPage}
                        >
                            <span className="f-icon icon-eye fs-17 mr-10"/> Посмотреть
                        </Button>
                    </div>,
                    <React.Fragment key='3'>
                        <SettingDevice device={device} key='3'/>
                    </React.Fragment>,
                    <React.Fragment key='4'>
                        <ThemeSwitcher/>
                    </React.Fragment>,
                    <React.Fragment key='5'>
                        <SettingLang key='5'/>
                    </React.Fragment>,
                    <React.Fragment key='6'>
                        {!isActivePage && <div className={'custom-ant'} key='6'>
                            <Button
                                key='6'
                                type="primary"
                                ghost
                                onClick={saveSchema}
                            >
                                <span className="f-icon icon-floppy-disk fs-17 mr-10"/> Сохранить
                            </Button>
                        </div>}
                    </React.Fragment>,
                    <React.Fragment key='7'>
                        {(!isActivePage && device === 'desktop') && <div className={'custom-ant'} key={'7'}>
                            <Tooltip title="Перенести для мобильной версии">
                                <Button
                                    key='7'
                                    icon={<span className="f-icon icon-copy fs-17"/>}
                                    onClick={showModalConfirm}
                                />
                            </Tooltip>
                        </div>}
                    </React.Fragment>,
                    <React.Fragment key='8'>
                        {!isActivePage && <div className={'custom-ant'} key='8'>
                            <Button
                                key='8'
                                icon={<span className="f-icon icon-cog fs-17"/>}
                                onClick={() => {
                                    setOpenLangs(true)
                                }}
                            />
                        </div>}
                    </React.Fragment>,
                ]}
            />
            <ModalNamePage open={openLangs} handleClose={handleClose} setMessages={setMessages}
                           title={'Редактровать страницу'} id={pageId} namePage={namePage}/>
        </div>

    );
}
