import React, {useEffect, useRef, useState} from 'react';
import styled from '../../../../styles/editorPage/workField.module.scss'
import {useSelector} from "react-redux";
import {getDevice} from "../../../../redux/selectors/editorSelectors";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Controller} from "swiper";
import BorderActive from "../../workFields/BorderActive";
import BlockSwiper from "../Blocks/BlockSwiper";
import {useInView} from "react-intersection-observer";
import {useBorderActive} from "../../../../hooks/useBorderActive";
import {useMediaQuery} from "react-responsive";

SwiperCore.use([Controller]);

const SwiperContainer = ({mainId, component, collection, isActivePage}) => {

	const [refInView, inView] = useInView({threshold: 0.1})

	const [firstSwiper, setFirstSwiper] = useState(null);
	const [isHiddenComponent, setHiddenComponent] = useState(false);

	const refImage = useRef()
	const device = useSelector(state => getDevice(state));
	const k5 = useMediaQuery({minWidth: 3840})
	const k4 = useMediaQuery({minWidth: 2160, maxWidth: 3839.98})
	const tableBig = useMediaQuery({minWidth: 768, maxWidth: 1199.98})
	const phone = useMediaQuery({maxWidth: 575.98})
	const slidePerView = k4
		? 7.3
		: k5
			? 8.3
			: tableBig
				? 4.3
				: device === 'tablet'
					? 3.3
					: device === 'phone'
						? 2.3
						: 4.4

	const {
		width, height, isHide,
		idActive, selectMainComponent,
		firstLoadSwiper, load, ref, resize
	} = useBorderActive(component, [component?.id])

	const select = (e) => {
		resize()
		selectMainComponent(e)
	}

	const getAnimateFlash = () => {
		if (component.animate === undefined || component.animate.showFlash) {
			return inView ? styled.mosaicCardShow : styled.mosaicCardHide
		}
		return ''
	}

	useEffect(() => {
		setHiddenComponent(true)
		const timer = setTimeout(() => {
			setHiddenComponent(false)
		}, 100)
		return () => clearTimeout(timer)
	}, [component?.slidePerView]);

	return (
		<>
		{!isHiddenComponent && (
			<div
				className='p-relative'
				ref={ref}
			>
				{!isHide && idActive.length === 1 && idActive[0] === component.id && !isActivePage &&
					<BorderActive
						width={width}
						height={height}
						id={[mainId]}
						collection={collection}
						btnDown={true}
						container={true}
					/>
				}
				<div
					onMouseDown={select}
					onContextMenu={(e) => e.preventDefault()}
					ref={refInView}
					className={`${styled.swiperContainer} ${getAnimateFlash()}`}
				>
					{/*swiper.updateSlides()*/}
					<Swiper
						className="mySwiper"
						onSwiper={setFirstSwiper}
						controller={{control: firstSwiper}}
						slidesPerView={component?.slidePerView ? component?.slidePerView : slidePerView}
						spaceBetween={+component?.styles?.spaceBetween || 1}
						slidesPerGroup={phone ? 1 : 4}
						speed={phone ? 300 : 1300}
						style={{
							marginBottom: component?.styles?.marginBottom,
						}}
					>
						{component?.components.map((item, key) =>
							<SwiperSlide key={key}>
								<BlockSwiper
									index={key}
									firstLoad={firstLoadSwiper}
									component={item}
									refImage={refImage}
									mainId={mainId}
									load={load}
									isActivePage={isActivePage}
									collection={collection}
								/>
							</SwiperSlide>
						)}
					</Swiper>
				</div>
			</div>
		)}
		</>
	);
};

export default SwiperContainer;