import React from 'react';
import styled from "../../../../styles/editorPage/rightBarEditor.module.scss";
import {Input} from "antd";

/**
 * @author Zholaman Zhumanov
 * @created 24.07.2024
 * @description
 * @last-updated
 * @update-description
 * @todo
 * @fixme
 * @param props
 * @constructor
 */
function SettingSlidePerGroup(props) {
    const {slidePerGroup, changeParams, isReplacement} = props

    return (
        <React.Fragment>
            {
                slidePerGroup !== undefined ? (
                    <div>
                        <div className={styled.textAndInput}>
                            <div className={styled.label}>
                                Кол. перехода:
                            </div>
                            <Input
                                style={{width: '75px'}}
                                size={'small'}
                                defaultValue={slidePerGroup}
                                onChange={(e) => changeParams('slidePerGroup', e.target.value, isReplacement)}
                            />
                        </div>
                    </div>
                ) : null
            }
        </React.Fragment>
    );
}

export default SettingSlidePerGroup;
