import React, {useEffect, useState} from 'react';
import styled from '../../styles/modals/modals.module.scss'
import {Checkbox, Modal} from "antd";
import {editorAPI} from "../../api/api";
import Row from "antd/es/grid/row";
import Col from "antd/es/grid/col";
import {useDispatch, useSelector} from "react-redux";
import {setTemplates} from "../../redux/actions/templatesAction";
import {getActivePage} from "../../redux/selectors/templatesSelectors";

export default function ModalCountries({open, handleClose, id, setMessages, active, name}) {
	const [shops, setShops] = useState([])
	const [countries, setCountries] = useState([])
	const activePage = useSelector(state => getActivePage(state));

	const dispatch = useDispatch()

	const changeCountries = (isoCode, active) => {
		if (active) {
			setCountries(prev => [...prev, isoCode])
		} else {
			setCountries(prev => prev.filter(item => item !== isoCode))
		}
	}

	useEffect(() => {
		setCountries(active.map(i => i.id))
	}, [active])

	const getShops = async () => {
		const res = await editorAPI.getShops()
		if (res.api_success) {
			setShops(res.list)
		}
	}
	useEffect(() => {
		getShops()
	}, [])


	const CountryItem = () => {
		return (
			<>
				{shops?.map((item,key) =>
						<Col span={12} key={key}>
							<Checkbox
								checked={countries.includes(item.id)}
								value={item.id}
								onChange={(e) => changeCountries(item.id, e.target.checked)}
							>
								{item.name}
							</Checkbox>
						</Col>
				)}

		</>
		)
	}
	const title ='Активация'

	const activate = async () => {
		if (!countries.length) {
			setMessages(['Выберите страны'])
			return;
		}
		const {api_success, api_messages} = await editorAPI.activate(id, countries)
		if (api_success) {
			setCountries([])
			handleClose()
			dispatch(setTemplates(activePage))
		}
		setMessages(api_messages)
	}


	const clickEnter = (e) => {
		if (e.keyCode == 13) {
			e.preventDefault()
			activate()
		}
	}

	return (
		<div>
			<Modal
				visible={open}
				onOk={activate}
				onCancel={handleClose}
				title={title + ' "' + name + '"'}
				okText="Активировать"
				cancelText="Отмена"
				closeIcon={<span className="f-icon icon-cross fs-17"/>}
			>
				<form noValidate autoComplete="off" onKeyDown={clickEnter} className={styled.checkboxCountries}>
					<div className={styled.title}>
						<div className={styled.text}>Выберите страны</div>
						<div className={styled.line}/>
					</div>
					<div className={styled.checkboxes}>
						<Row>
							<CountryItem/>
						</Row>
					</div>
				</form>
			</Modal>
		</div>
	);
}