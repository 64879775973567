import React, {useEffect, useRef, useState} from 'react';
import styled from '../../../../styles/editorPage/workField.module.scss'
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Controller, Autoplay } from "swiper";
import BorderActive from "../../workFields/BorderActive";
import BlockSwiper from "../Blocks/BlockSwiper";
import {useInView} from "react-intersection-observer";
import {useBorderActive} from "../../../../hooks/useBorderActive";
import {useSelector} from "react-redux";
import {getAutoplay, getComponentCollection} from "../../../../redux/selectors/editorSelectors";
import BlockSaintLaurent from "../Blocks/BlockSaintLaurent";

SwiperCore.use([Controller, Autoplay ]);

const SwiperContainerPhone = ({mainId, component, collection, isActivePage, outsideComponent, minContainer, device}) => {
	const [refInView, inView] = useInView({threshold: 0.1})

	const [firstSwiper, setFirstSwiper] = useState(null);
	const [isHiddenComponent, setHiddenComponent] = useState(false);
	const isAutoplayState = useSelector(state => getAutoplay(state));

	const autoplay = localStorage.getItem('autoplay')
	const [isAutoplay, setAutoplay] = useState(!!autoplay);

	useEffect(() => {
		setAutoplay(!isAutoplay)
		setHiddenComponent(true)
		const timer = setTimeout(() => {
			setHiddenComponent(false)
		}, 100)
		return () => clearTimeout(timer)
	}, [isAutoplayState]);

	const {
		width, height, isHide,
		idActive, selectMainComponent,
		firstLoadSwiper, load, ref, resize
	} = useBorderActive(component, [component?.id])

	const select = (e) => {
		resize()
		selectMainComponent(e)
	}

	const getAnimateFlash = () => {
		if (component.animate === undefined || component.animate.showFlash) {
			return inView ? styled.mosaicCardShow : styled.mosaicCardHide
		}
		return ''
	}

	return (
		<>
		{!isHiddenComponent && (
			<div
				className='p-relative'
				ref={ref}
			>
				{!isHide && idActive.length === 1 && idActive[0] === component.id && !isActivePage &&
					<BorderActive
						width={width}
						height={height}
						id={[mainId]}
						collection={collection}
						btnDown={true}
						container={true}
					/>
				}
				<div
					onMouseDown={select}
					onContextMenu={(e) => e.preventDefault()}
					ref={refInView}
					className={`${styled.swiperContainer} ${getAnimateFlash()}`}
				>
					<div className="slider-width-progress">
						<Swiper
							spaceBetween={0}
							slidesPerView={1}
							loop
							watchSlidesProgress={true}
							autoplay={isAutoplay ? {
								delay: component?.autoplay?.delay || 3000,
								disableOnInteraction: false,
							} : {
								delay: 99999999999,
								disableOnInteraction: false,
							}}
							speed={component?.autoplay?.speed || 700}
							nested={true}
							onSwiper={setFirstSwiper}
							controller={{control: firstSwiper}}
							className={`mySwiper`}
						>
							{component?.components.map((item, id) =>
								<SwiperSlide key={id} className={`${!id ? 'firstSlide' : ''}`}>
									{
										outsideComponent ? (
											<div className={`${minContainer ? (device === "desktop" ? styled.minContainer : styled.minContainerSm) : ""}`}>
												<BlockSaintLaurent
													index={id}
													load={load}
													mainId={mainId}
													component={item}
													collection={collection}
													isActivePage={isActivePage}
													firstLoad={firstLoadSwiper}
													minContainer={minContainer}
												/>
											</div>
										) : (
											<BlockSwiper
												index={id}
												firstLoad={firstLoadSwiper}
												component={item}
												mainId={mainId}
												load={load}
												phone={true}
												isActivePage={isActivePage}
												collection={collection}
											/>
										)
									}

									{(component?.isProgressBar === undefined || component?.isProgressBar) &&
										<div className='own-progress'>
											<div
												className='bar-transition'
												style={{transition: `width ${component?.autoplay?.delay || 3000}ms ease 2s`}}
											/>
											<div
												className={!id ? 'bar-transition-first' : 'bar-transition'}
												style={{transition: `width ${component?.autoplay?.delay || 3000}ms ease 2s`}}
											/>
										</div>
									}
								</SwiperSlide>
							)}
						</Swiper>
					</div>
				</div>
			</div>
		)}
		</>
	);
};

export default SwiperContainerPhone;
