import React from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import {Checkbox, Input} from "antd";

const SettingProgressBar = ({isProgressBar, changeParams, isReplacement}) => {

    return (
        <React.Fragment>
            {isProgressBar !== undefined ? (
                <div
                    className={styled.rightStylesMainMargin}
                    key={isProgressBar}
                    style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}
                >
                    <div style={{fontWeight: 500}}>
                        Прогрес-бар
                    </div>
                    <div className={styled.checkbox}>
                        <Checkbox
                            checked={isProgressBar}
                            onChange={(e) => changeParams('isProgressBar', e.target.checked, isReplacement)}
                        >
                            Показать
                        </Checkbox>
                    </div>
                </div>
            ) : null
            }
        </React.Fragment>
    );
};


export default SettingProgressBar;
