import {ADD_TEMPLATE_LIST, CHANGE_ACTIVE_PAGE, CHANGE_PAGINATION_DATA, PAGE_TYPE} from '../types'

let initialState = {
    templates: [],
    activePage: '1',
    paginationData: {
        total: 0,
        limit: 10
    },
    pageType: "page"
};

const templatesReducer = (state = initialState, action) => {

    switch (action.type) {
        case ADD_TEMPLATE_LIST:
            return {
                ...state, templates: action.templates
            }
        case CHANGE_ACTIVE_PAGE:
            return {
                ...state, activePage: action.page
            }
        case CHANGE_PAGINATION_DATA:
            return {
                ...state, paginationData: action.data
            }
        case PAGE_TYPE:
            return {
                ...state, pageType: action.value
            }
        default:
            return state;
    }
}

export const setTemplateList = (templates) => ({type: ADD_TEMPLATE_LIST, templates});
export const setActivePage = (page) => ({type: CHANGE_ACTIVE_PAGE, page});
export const setPaginationData = (data) => ({type: CHANGE_PAGINATION_DATA, data});
export const setPageType = (value) => ({type: PAGE_TYPE, value});

export default templatesReducer;
