import React, {useEffect, useMemo} from 'react';
import styled from '../../../../styles/editorPage/rightBarEditor.module.scss'
import {Checkbox, Input, Radio, Select} from "antd";
import {Option} from "antd/es/mentions";
import rMarginIco from '../../../../assets/sprite/margin_r.svg'
import lMarginIco from '../../../../assets/sprite/margin_l.svg'
import bMarginIco from '../../../../assets/sprite/margin_b.svg'
import tMarginIco from '../../../../assets/sprite/margin_t.svg'


const SettingStyles = ({styles, changeParams, isReplacement, parentStylesTextSliced, updateCallback}) => {
    const options = ["Futura PT", "Bodoni Cyrillic", "No Name", "AnastasiaScript", "Dubai Light", "Helvetica Bold", "Venski Sad One", "CormorantGaramond", "Сinzel"];
    const [inputSelect, setInputSelect] = React.useState('');
    const initialSort = [
        'selfStyle', 'alignSelf', 'textAlign', 'margin',
        'padding', 'color', "colorDark", 'invertedColor',
        'borderColor', 'borderColorDark', 'background', "backgroundDark",
        'fontFamily', 'fontStyle', 'fontWeight', 'fontSize', 'fontSizeTime',
        'fontSizeType', 'gridGap', 'spaceBetween', 'marginBottom',
        'gridTemplateColumns',
    ]

    useEffect(() => {
        if (styles?.fontFamily) {
            setInputSelect(styles.fontFamily)
        }
    }, [])

    const sortStyles = useMemo(() => {
        let result = {}
        if (styles) {
            const propsKeys = Object.keys(styles)
            for (let key of initialSort) {
                if (propsKeys.includes(key))
                    result = {...result, [key]: styles[key]}
            }
            return result
        }
        return null
    }, [styles])

    const selectName = (val) => {
        switch (val) {
            case 'gridGap':
                return 'Отступ блоков';
            case 'spaceBetween':
                return 'Отступ блоков';
            case 'marginBottom':
                return 'Отступ снизу';
            case 'margin':
                return 'Отступы (px)'
            case 'padding':
                return 'Внутренние отступы (px)'
            case 'background':
                return 'Цвет Фона'
            case 'backgroundDark':
                return 'Цвет Фона (Dark)'
            case 'borderColor':
                return 'Цвет рамки';
            case 'borderColorDark':
                return 'Цвет рамки (Dark)';
            case 'color':
                return 'Цвет текста';
            case 'colorDark':
                return 'Цвет текста (Dark)';
            case 'invertedColor':
                return 'Цвет текста (inverted)';
            case 'invertedColorDark':
                return 'Цвет текста (inverted/Dark)';
            case 'fontSize':
                return 'Размер';
            case 'fontStyle':
                return 'Стиль';
            case 'fontSizeTime':
                return 'Размер(Цифры)';
            case 'fontSizeType':
                return 'Размер(Текст)';
            case 'fontWeight':
                return 'Ширина';
            case 'selfStyle':
                return 'Собственные стили';
            default:
                return "Нет таких значений";
        }
    }

    const getMarginName = (val) => {
        switch (val) {
            case 0:
                return tMarginIco;
            case 1:
                return rMarginIco
            case 2:
                return bMarginIco;
            case 3:
                return lMarginIco;
            default:
                return "Нет таких значений";
        }
    }


    const setArrayParams = (name, array, val, key) => {
        const newArray = [...array]
        newArray.splice(key, 1, val)

        if (changeParams) {
            changeParams('styles', {[name]: newArray}, isReplacement)
        }

        if (updateCallback) {
            updateCallback({[name]: newArray})
        }
    }

    const changeText = (val) => {
        if (val.fontFamily === 'Bodoni Cyrillic' || val.fontFamily === 'No Name' || val.fontFamily === "Venski Sad One" || val.fontFamily === 'CormorantGaramond' || val.fontFamily === "Сinzel") {
            if (changeParams) changeParams('styles', {fontWeight: 400}, isReplacement)
            if (updateCallback) updateCallback({fontWeight: 400})
        }
        if (val.fontFamily === 'Dubai Light' || val.fontFamily === 'AnastasiaScript') {
            if (changeParams) changeParams('styles', {fontWeight: 300}, isReplacement)
            if (updateCallback) updateCallback({fontWeight: 300})
        }
        if (val.fontFamily === 'Helvetica Bold') {
            if (changeParams) changeParams('styles', {fontWeight: 700}, isReplacement)
            if (updateCallback) updateCallback({fontWeight: 700})
        }

        if (changeParams) changeParams('styles', val, isReplacement)
        if (updateCallback) updateCallback(val)
    }

    const changeSelfStyles = (checked) => {
        if (checked) {
            if (changeParams) changeParams('styles', {selfStyle: checked}, isReplacement)
            if (updateCallback) updateCallback({selfStyle: checked})
        } else {
            if (changeParams) {
                changeParams(
                    'styles',
                    {
                        selfStyle: checked,
                        color: parentStylesTextSliced?.color,
                        fontFamily: parentStylesTextSliced?.fontFamily,
                        fontWeight: parentStylesTextSliced?.fontWeight,
                        fontSize: parentStylesTextSliced?.fontSize,
                    },
                    isReplacement
                )
            }

            if (updateCallback) {
                updateCallback(
                    {
                        selfStyle: checked,
                        color: parentStylesTextSliced?.color,
                        fontFamily: parentStylesTextSliced?.fontFamily,
                        fontWeight: parentStylesTextSliced?.fontWeight,
                        fontSize: parentStylesTextSliced?.fontSize,
                    }
                )
            }
        }
    }

    return (
        <div>
            {sortStyles
                ?
                <div>
                    {Object.entries(sortStyles).map((item, key) =>
                        <div key={key}>
                            {item[0] === 'fontSize' || item[0] === 'fontSizeTime' || item[0] === 'fontSizeType' || item[0] === 'gridGap' || item[0] === 'marginBottom'
                                ?
                                <div className={styled.textAndButtoms} style={{margin: '10px 15px'}}>
                                    <div className={styled.label}>
                                        {selectName(item[0])}
                                    </div>
                                    <div className={`${styled.buttomItems}`}>
                                        <Input
                                            style={{width: '75px'}}
                                            size={'small'}
                                            defaultValue={+item[1].split('px')[0]}
                                            onChange={(e) => {
                                                if (changeParams) changeParams('styles', {[item[0]]: Number(e.target.value) + 'px'}, isReplacement)
                                                if (updateCallback) updateCallback({[item[0]]: Number(e.target.value) + 'px'})
                                            }}
                                        />
                                    </div>
                                </div>
                                :
                                item[0] === 'spaceBetween'
                                    ?
                                    <div className={styled.textAndButtoms} style={{margin: '10px 15px'}}>
                                        <div className={styled.label}>
                                            {selectName(item[0])}
                                        </div>
                                        <div className={`${styled.buttomItems}`}>
                                            <Input
                                                style={{width: '75px'}}
                                                size={'small'}
                                                defaultValue={item[1]}
                                                onChange={(e) => {
                                                    if (changeParams) changeParams('styles', {[item[0]]: Number(e.target.value)}, isReplacement)
                                                    if (updateCallback) updateCallback({[item[0]]: Number(e.target.value)})
                                                }}
                                            />
                                        </div>
                                    </div>
                                    :
                                    item[0] === 'borderColor' || item[0] === 'color' || item[0] === 'borderColorDark' || item[0] === 'colorDark' ||
                                    item[0] === 'background' || item[0] === 'backgroundDark' || item[0] === 'invertedColor' || item[0] === 'invertedColorDark'
                                        ?
                                        <div className={styled.textAndButtoms} style={{margin: '15px'}}>
                                            <div className={styled.label}>
                                                {selectName(item[0])}
                                            </div>
                                            <div
                                                className={styled.buttomItems}
                                            >
                                                <div>
                                                    <input
                                                        className={styled.inputColor}
                                                        type="color"
                                                        value={item[1]}
                                                        onChange={(e) => {
                                                            if (changeParams) changeParams('styles', {[item[0]]: e.target.value}, isReplacement)
                                                            if (updateCallback) updateCallback({[item[0]]: e.target.value})
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    {item[1]}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        item[0] === 'selfStyle'
                                            ?
                                            <div className={styled.rightStylesMainMargin} key={styles.selfStyle}>
                                                <div className={styled.checkbox}>
                                                    <Checkbox
                                                        checked={styles.selfStyle}
                                                        onChange={(e) => changeSelfStyles(e.target.checked)}
                                                    >
                                                        {selectName(item[0])}
                                                    </Checkbox>
                                                </div>
                                            </div>
                                            :
                                            item[0] === 'margin' || item[0] === 'padding'
                                                ?
                                                <div>
                                                    <h3>{selectName(item[0])}</h3>
                                                    <div className={styled.listBlock}>
                                                        {item[1].map((val, key) =>
                                                            <div className={styled.element} key={key}>
                                                                <div className={styled.label}>
                                                                    <img src={getMarginName(key)} alt={item[0]}/>
                                                                </div>
                                                                <div className={styled.inputWrapper}>
                                                                    <Input
                                                                        size='small'
                                                                        defaultValue={+val.split('px')[0]}
                                                                        onChange={
                                                                            (e) => setArrayParams(item[0], item[1], Number(e.target.value) + 'px', key)
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                :
                                                item[0] === 'gridTemplateColumns'
                                                    ?
                                                    <div>
                                                        <h3>Размеры блоков</h3>
                                                        <div className={styled.listBlock}>
                                                            {item[1].map((val, key) =>
                                                                <div className={styled.element} key={key}>
                                                                    <div className={styled.label}>
                                                                        B{key + 1}
                                                                    </div>
                                                                    <div className={styled.inputWrapper}>
                                                                        <Select
                                                                            style={{
                                                                                width: 110,
                                                                            }}
                                                                            defaultValue={item[1]}
                                                                            size={'small'}
                                                                            onChange={(e, target) => setArrayParams(item[0], item[1], target.value, key)}
                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                        >
                                                                            <Option value="0.5fr">0.5fr</Option>
                                                                            <Option value="1fr">1fr</Option>
                                                                            <Option value="1.5fr">1.5fr</Option>
                                                                            <Option value="2fr">2fr</Option>
                                                                            <Option value="2fr">3fr</Option>
                                                                            <Option value="2fr">7fr</Option>
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    :
                                                    item[0] === 'alignSelf'
                                                        ?
                                                        <div>
                                                            <h3>Расположение элементов</h3>
                                                            <div className={styled.groupButtons}>
                                                                <Radio.Group
                                                                    onChange={(e) => {
                                                                        if (changeParams) changeParams('styles', {alignSelf: e.target.value}, isReplacement)
                                                                        if (updateCallback) updateCallback({alignSelf: e.target.value})
                                                                    }}
                                                                    defaultValue={item[1]}>
                                                                    <Radio.Button value="start"><span
                                                                        className="icon-text-align-left fs-17 "/></Radio.Button>
                                                                    <Radio.Button value="center"><span
                                                                        className="icon-text-align-center fs-17 "/></Radio.Button>
                                                                    <Radio.Button value="end"><span
                                                                        className="icon-text-align-right fs-17 "/></Radio.Button>
                                                                </Radio.Group>
                                                            </div>
                                                        </div>
                                                        :
                                                        item[0] === 'textAlign'
                                                            ?
                                                            <div>
                                                                <h3>Выравнивание текста</h3>
                                                                <div className={styled.groupButtons}>
                                                                    <Radio.Group
                                                                        onChange={(e) => {
                                                                            if (changeParams) changeParams('styles', {textAlign: e.target.value}, isReplacement)
                                                                            if (updateCallback) updateCallback({textAlign: e.target.value})
                                                                        }}
                                                                        defaultValue={item[1]}>
                                                                        <Radio.Button value="left"><span
                                                                            className="icon-text-align-left fs-17 "/></Radio.Button>
                                                                        <Radio.Button value="center"><span
                                                                            className="icon-text-align-center fs-17 "/></Radio.Button>
                                                                        <Radio.Button value="right"><span
                                                                            className="icon-text-align-right fs-17 "/></Radio.Button>
                                                                    </Radio.Group>
                                                                </div>
                                                            </div>
                                                            :
                                                            item[0] === 'fontFamily'
                                                                ?
                                                                <div className={styled.block}>
                                                                    <Select
                                                                        showSearch
                                                                        style={{
                                                                            width: 315,
                                                                        }}
                                                                        placeholder="Search to Select"
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.includes(input)}
                                                                        filterSort={(optionA, optionB) =>
                                                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                                        }
                                                                        defaultValue={item[1]}
                                                                        onChange={(event, item) => {
                                                                            changeText({fontFamily: item.value})
                                                                        }}
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                    >
                                                                        <Option value="Bodoni Cyrillic">Bodoni
                                                                            Cyrillic</Option>
                                                                        <Option value="Futura PT">Futura PT</Option>
                                                                        <Option value="No Name">No Name 37</Option>
                                                                        <Option value="AnastasiaScript">Anastasia
                                                                            Script</Option>
                                                                        <Option value="Dubai Light">Dubai Light</Option>
                                                                        <Option value="Сinzel">Сinzel</Option>
                                                                        <Option
                                                                            value="CormorantGaramond">CormorantGaramond</Option>
                                                                        <Option value="Helvetica Bold">Helvetica
                                                                            Bold</Option>
                                                                        <Option value="Venski Sad One">Venski Sad
                                                                            One</Option>
                                                                    </Select>
                                                                </div>
                                                                :
                                                                item[0] === 'fontStyle'
                                                                    ?
                                                                    <div className={styled.block}>
                                                                        <Select
                                                                            style={{
                                                                                width: 315,
                                                                            }}
                                                                            defaultValue={item[1]}
                                                                            onChange={(e, item) => {
                                                                                if (changeParams) changeParams('styles', {fontStyle: item.value}, isReplacement)
                                                                                if (updateCallback) updateCallback({fontStyle: item.value})
                                                                            }}
                                                                            getPopupContainer={trigger => trigger?.parentNode}
                                                                        >
                                                                            <Option value="normal">Normal</Option>
                                                                            <Option value="italic">Italic</Option>
                                                                        </Select>
                                                                    </div>
                                                                    :
                                                                    item[0] === 'fontWeight'
                                                                        ?
                                                                        <div className={styled.textAndSwitch}>
                                                                            <div className={styled.label}>
                                                                                {selectName(item[0])}
                                                                            </div>
                                                                            <div
                                                                                className={styled.inputWrapper}
                                                                            >
                                                                                <div
                                                                                    className={`${styled.buttomItems}`}>
                                                                                    {styles?.fontFamily === "Futura PT"
                                                                                        ?
                                                                                        <Select
                                                                                            style={{
                                                                                                width: 75,
                                                                                            }}
                                                                                            value={item[1]}
                                                                                            size={'small'}
                                                                                            onChange={(e, item) => {
                                                                                                if (changeParams) changeParams('styles', {fontWeight: item.value}, isReplacement)
                                                                                                if (updateCallback) updateCallback({fontWeight: item.value})
                                                                                            }}
                                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                                        >
                                                                                            <Option
                                                                                                value={300}>300</Option>
                                                                                            <Option
                                                                                                value={400}>400</Option>
                                                                                            <Option
                                                                                                value={500}>500</Option>
                                                                                            <Option
                                                                                                value={900}>900</Option>
                                                                                        </Select>
                                                                                        :
                                                                                        styles?.fontFamily === "Bodoni Cyrillic" || styles?.fontFamily === "No Name"
                                                                                            ?
                                                                                            <Select
                                                                                                style={{
                                                                                                    width: 75,
                                                                                                }}
                                                                                                value={item[1]}
                                                                                                size={'small'}
                                                                                                onChange={(e, item) => {
                                                                                                    if (changeParams) changeParams('styles', {fontWeight: item.value}, isReplacement)
                                                                                                    if (updateCallback) updateCallback({fontWeight: item.value})
                                                                                                }}
                                                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                                            >
                                                                                                <Option
                                                                                                    value={400}>400</Option>
                                                                                            </Select>
                                                                                            :
                                                                                            (styles?.fontFamily === "Venski Sad One" || styles?.fontFamily === "Сinzel" || styles?.fontFamily === "CormorantGaramond")
                                                                                                ?
                                                                                                <Select
                                                                                                    style={{
                                                                                                        width: 75,
                                                                                                    }}
                                                                                                    value={item[1]}
                                                                                                    size={'small'}
                                                                                                    onChange={(e, item) => {
                                                                                                        if (changeParams) changeParams('styles', {fontWeight: item.value}, isReplacement)
                                                                                                        if (updateCallback) updateCallback({fontWeight: item.value})
                                                                                                    }}
                                                                                                    getPopupContainer={trigger => trigger.parentNode}
                                                                                                >
                                                                                                    <Option
                                                                                                        value={400}>400</Option>
                                                                                                    <Option
                                                                                                        value={700}>700</Option>
                                                                                                </Select>
                                                                                                :
                                                                                                styles?.fontFamily === "AnastasiaScript"
                                                                                                    ?
                                                                                                    <Select
                                                                                                        style={{
                                                                                                            width: 75,
                                                                                                        }}
                                                                                                        value={item[1]}
                                                                                                        size={'small'}
                                                                                                        onChange={(e, item) => {
                                                                                                            if (changeParams) changeParams('styles', {fontWeight: item.value}, isReplacement)
                                                                                                            if (updateCallback) updateCallback({fontWeight: item.value})
                                                                                                        }}
                                                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                                                    >
                                                                                                        <Option
                                                                                                            value={300}>300</Option>
                                                                                                    </Select>
                                                                                                    :
                                                                                                    styles?.fontFamily === "Dubai Light"
                                                                                                        ?
                                                                                                        <Select
                                                                                                            style={{
                                                                                                                width: 75,
                                                                                                            }}
                                                                                                            value={item[1]}
                                                                                                            size={'small'}
                                                                                                            onChange={(e, item) => {
                                                                                                                if (changeParams) changeParams('styles', {fontWeight: item.value}, isReplacement)
                                                                                                                if (updateCallback) updateCallback({fontWeight: item.value})
                                                                                                            }}
                                                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                                                        >
                                                                                                            <Option
                                                                                                                value={300}>300</Option>
                                                                                                        </Select>
                                                                                                        :
                                                                                                        styles?.fontFamily === "Helvetica Bold"
                                                                                                            ?
                                                                                                            <Select
                                                                                                                style={{
                                                                                                                    width: 75,
                                                                                                                }}
                                                                                                                value={item[1]}
                                                                                                                size={'small'}
                                                                                                                onChange={(e, item) => {
                                                                                                                    if (changeParams) changeParams('styles', {fontWeight: item.value}, isReplacement)
                                                                                                                    if (updateCallback) updateCallback({fontWeight: item.value})
                                                                                                                }}
                                                                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                                                            >
                                                                                                                <Option
                                                                                                                    value={700}>700</Option>
                                                                                                            </Select>
                                                                                                            :
                                                                                                            <></>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                            }
                        </div>
                    )
                    }
                </div>
                :
                <></>
            }
        </div>
    );
};

export default SettingStyles;
